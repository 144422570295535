import styled from 'styled-components'
import Logo from '../../assets/svg/logo.svg'

const BaseLoader = () => {
  return (
    <LoaderWrap>
        <div className='pulse'>
          <img className='img' src={Logo} alt="logo" />
        </div>
    </LoaderWrap>
  );
};

const LoaderWrap = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  .pulse{
    width: 150px;
    height: 150px;
    background: linear-gradient(hsla(var(--clr-blue-shade-200)), hsla(var(--clr-blue-shade-400)));
    border-radius: 50%;
    display: grid;
    place-items: center;
    position: relative;
  
    
  }

  .pulse:before,
  .pulse:after{
    content:"";
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: hsla(var(--clr-blue-shade-050));
    border-radius: 50%;
    opacity: 0.3;
  
  }

  .pulse:before{
    animation: pulse 2s ease-out infinite;
  }
  .pulse:after{
    animation: pulse 2s 1s ease-out infinite;
  }
 

  @keyframes pulse{
    100%{
      transform: scale(2.5);
      opacitiy: 0;
    }

  }

`



export default BaseLoader;
