import ButtonSpinner from "components/Loaders/ButtonSpinner";
import { useAppContext } from "context/AppContext";
import React, { SetStateAction, Dispatch, useState, useEffect } from "react";
import styled from "styled-components";
import BannnerService from "services/BannerService";
import { ItemType } from ".";
import { NormalPromoForm } from "./BundleOffer/NormalPromoForm";
import { BackDrop } from "components/BackDrop";
import { BundlePromoForm } from "./BundleOffer/BundlePromoForm";
import { SkuSelect } from "./BundleOffer/SkuSelect";
import { RewardSelect } from "./BundleOffer/RewardSelect";
import { BundleFormFinal } from "./BundleOffer/BundleFormFinal";

interface ModalProps {
  item: ItemType;
  display: boolean;
  setIsModal: React.Dispatch<SetStateAction<boolean>>;
  skuProducts: any;
}

export const ActionModal: React.FC<ModalProps> = ({
  item,
  display,
  setIsModal,
  skuProducts,
}) => {
  const { dispatch } = useAppContext();
  const [isReset, setIsReset] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formView, setFormView] = useState<String>(
    item.banner_type == "buy_actions" ? "bundle" : "normal"
  );
  const [selectedSkuProduct, setSelectedSkuProduct] = useState<any>([]);
  const [selectedRewardProduct, setSelectedRewardProduct] = useState<any>([]);
  const [selectedDepots, setSelectedDepots] = useState<[]>([]);
  const [selectedRegions, setSelectedRegions] = useState<[]>([]);
  const [bundleFormData, setBundleFormData] = useState({});

  const handleModalClose = () => {
    setIsModalOpen(false);
    setIsReset(false);
  };

  const handleCloseButton = () => {
    setIsReset(true);
    setIsModalOpen(false);
    setFormView(item.banner_type == "buy_actions" ? "bundle" : "normal");
    setSelectedSkuProduct([]);
    setSelectedRewardProduct([]);
  };

  const handleEdit = () => {
    setIsModalOpen(true);
  };

  async function onDisable(id: string, is_active: boolean) {
    const status = {
      is_active: !is_active,
    };
    try {
      dispatch({ type: "action-loading" });
      const {
        data: { statusCode, data },
      } = await BannnerService.editBanner(id, status);
      if (statusCode === 200 && data) item.is_active = !is_active;
    } catch (error) {
    } finally {
      dispatch({ type: "action-loaded" });
    }
  }

  if (!display) return null;
  return (
    <>
      <StyledOverlay />
      <StyledModal className="modal">
        <Close className="pointer close" onClick={() => setIsModal(false)} />
        <p
          className="fs-100 fw-400 text-black-900 pointer"
          onClick={() => onDisable(item._id, item.is_active)}
        >
          {item.is_active ? "Deactive Offer" : "Active Offer"}
        </p>
        <p
          className="fs-100 fw-400 text-black-900 pointer"
          onClick={() => handleEdit()}
        >
          Edit Offer
        </p>

        <BackDrop
          isOpen={isModalOpen}
          handleClose={handleModalClose}
          handleCloseButton={handleCloseButton}
        >
          {}
          {formView === "normal" && (
            <NormalPromoForm
              setSelectedDepots={setSelectedDepots}
              selectedDepots={selectedDepots}
              setSelectedRegions={setSelectedRegions}
              selectedRegions={selectedRegions}
              handleCloseButton={handleCloseButton}
              item={item}
            />
          )}
          {formView === "bundle" && (
            <BundlePromoForm
              setFormView={setFormView}
              setBundleFormData={setBundleFormData}
              bundleFormData={bundleFormData}
              isReset={isReset}
              item={item}
            />
          )}
          {formView === "sku" && (
            <SkuSelect
              setFormView={setFormView}
              skuProducts={skuProducts}
              setSelectedSkuProduct={setSelectedSkuProduct}
              selectedSkuProduct={selectedSkuProduct}
              item={item}
            />
          )}
          {formView === "reward" && (
            <RewardSelect
              setFormView={setFormView}
              skuProducts={skuProducts}
              setSelectedRewardProduct={setSelectedRewardProduct}
              selectedRewardProduct={selectedRewardProduct}
              selectedSkuProduct={selectedSkuProduct}
              setSelectedSkuProduct={setSelectedSkuProduct}
              item={item}
            />
          )}
          {formView === "final" && (
            <BundleFormFinal
              setSelectedDepots={setSelectedDepots}
              selectedDepots={selectedDepots}
              selectedRegions={selectedRegions}
              setSelectedRegions={setSelectedRegions}
              bundleFormData={bundleFormData}
              selectedSkuProduct={selectedSkuProduct}
              selectedRewardProduct={selectedRewardProduct}
              handleCloseButton={handleCloseButton}
              setBundleFormData={setBundleFormData}
              item={item}
            />
          )}
        </BackDrop>
      </StyledModal>
    </>
  );
};

const Close = ({ ...props }) => {
  const { state } = useAppContext();
  const isLoading = state?.isActionLoading;

  return (
    <span {...props}>
      {isLoading ? (
        <ButtonSpinner />
      ) : (
        <svg width="14" height="15" xmlns="http://www.w3.org/2000/svg">
          <path
            d="m11.596.782 2.122 2.122L9.12 7.499l4.597 4.597-2.122 2.122L7 9.62l-4.595 4.597-2.122-2.122L4.878 7.5.282 2.904 2.404.782l4.595 4.596L11.596.782Z"
            fill="#FFF"
            fillRule="evenodd"
          />
        </svg>
      )}
    </span>
  );
};

const StyledOverlay = styled.td`
  background-color: rgba(0, 0, 0, 0.6);
  inset: 0;
  position: fixed;
  transition: transform 600ms ease-in-out;
  z-index: 999;
`;

const StyledModal = styled.td`
  background-color: hsla(var(--clr-light));
  position: absolute;
  z-index: 999;
  right: 5%;
  top: 0;
  padding: 0.7em 0 !important;
  width: 9.375em;

  .close {
    position: absolute;
    top: -10px;
    right: -5%;
    z-index: 9;
    background-color: hsla(var(--clr-blue-tint-050));
    border-radius: 50%;
    height: 24px;
    width: 24px;
    display: inline-grid;
    place-items: center;
  }
  p {
    padding: 0.5em 1em;
    width: 100%;
    text-align: left;
    &:hover {
      // background: hsla(var(--clr-yellow-tint-900));
    }
  }
`;
