import React, { useState } from "react";
import styled from "styled-components";

interface TableProps<TItem> {
  headers: { key: number; item: string }[];
  items: TItem[];
  renderItem: (item: TItem) => React.ReactNode;
}

export const Table = <TItem,>({
  items,
  headers,
  renderItem,
}: TableProps<TItem>) => {
  return (
    <StyledTable>
      <thead>
        <tr>
          {headers.map((header) => {
            return <th key={header.key}>{header.item}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {items.map((item) => {
          return renderItem(item);
        })}
      </tbody>
    </StyledTable>
  );
};

const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  padding: 0 3em;
  font-size: var(--fs-200);
  min-width: 400px;
  overflow-y: hidden;

  thead {
    position: sticky;
    top: 0;
    background-color: #ececec;
    z-index: 9;

    tr {
      text-align: left;
    }
  }

  th,
  td {
    padding: 1.1em 3em;
  }
  td:first-child {
    text-decoration: underline;
    cursor: pointer;
  }

  tbody tr:nth-child(odd) {
    background-color: hsla(var(--clr-light));
  }
  tbody tr:hover {
    background-color: #e9e9ef;
  }

  .table-date p {
    width: 65px;
    text-align: center;
  }
  .table-date p:nth-child(2) {
    font-weight: 600;
    font-size: 14px;
  }
`;

// export const Component = <TItem,>(props: ComponentProps<TItem>) => {
// 	// console.log(props.renderItem);
// 	// props.items.map((item) => console.log(item));
// 	// const Node = props.renderItem;
// 	// return <Node />;
// 	return props.renderItem;
// };

// interface TableRowProps<TItem> {
// 	item: TItem;
// }

// export const GenericTable = ({}) => {
// 	return (
// 		<>
// 			<Component
// 				items={[{ name: 1, first: 'ndu', last: 'bisi' }]}
// 				renderItem={(item) => {
// 					return (
// 						<>
// 							<h1>{item.name}</h1>
// 						</>
// 					);
// 				}}
// 			/>
// 			<Component
// 				items={[{ id: 1, gender: 'female', age: 30 }]}
// 				renderItem={(item) => {
// 					return null;
// 				}}
// 			/>
// 		</>
// 	);
// };
