import styled from "styled-components";
import { Link } from "react-router-dom";

interface Dashboard {
  icon: string;
  title: string;
  content: string;
  btnText: string;
  link: string;
}

const Dashboard: React.FC<Dashboard> = ({
  icon,
  title,
  content,
  btnText,
  link,
}) => {
  return (
    <Container>
      <img src={icon} alt={title} />
      <h3>{title}</h3>
      <p>{content}</p>
      <div>
        <Link to={link}>{btnText}</Link>
      </div>
    </Container>
  );
};

const Container = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 350px;
  border-radius: 10px;

  & img {
    width: 25px;
  }

  & h3 {
    font-size: 16px;
    font-weight: bold;
    color: #413d45;
  }
  & p {
    font-size: 14px;
    color: #777;
  }
  & div {
    display: flex;
  }
  & a {
    color: #262262;
    border: 1px solid #262262;
    border-radius: 3px;
    display: block;
    padding: 10px;
    font-size: 0.8rem;
    font-weight: bold;
  }
`;

export default Dashboard;
