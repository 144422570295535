import { useEffect, useState, Dispatch, SetStateAction } from "react";
import styled from "styled-components";

interface MultiLayoutProps {
  title: string;
  id: string;
  setIsModal: Dispatch<SetStateAction<boolean>>;
  handleCloseButton: () => void;
  setQuestionsForms: Dispatch<SetStateAction<[]>>;
  questionsForms: any;
  setSelectedId: Dispatch<SetStateAction<string>>;
  setFormView: Dispatch<SetStateAction<string>>;
}

const InlineLayout: React.FC<MultiLayoutProps> = ({
  title,
  id,
  setQuestionsForms,
  questionsForms,
  setIsModal,
  setSelectedId,
  setFormView,
}) => {
  const handleDeleteQuestion = () => {
    let filteredQestions = questionsForms.filter((data: any) => id !== data.id);
    setQuestionsForms(filteredQestions);
    setSelectedId("");
  };

  const handleEditQuestion = () => {
    setIsModal(true);
    setSelectedId(id);
    setFormView("inline");
  };

  return (
    <Container>
      <div className="question">
        <li>{title}</li>
      </div>
      <div className="quest-placeholder">
        <textarea placeholder="Space for comment" readOnly></textarea>
      </div>
      <div className="btn-container">
        <button className="btns btn-outline" onClick={handleDeleteQuestion}>
          Delete
        </button>
        <button className="btns" onClick={handleEditQuestion}>
          Edit
        </button>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  border-bottom: 2px solid #b3b1b5;
  padding: 30px;

  .question {
    margin-bottom: 20px;
  }
  .question li {
    margin-left: 20px;
    font-size: 1.1rem;
    font-weight: 600;
  }
  .quest-placeholder {
    width: 35%;
  }
  .quest-placeholder textarea {
    width: 100%;
    height: 100px;
    padding: 15px;
    outline: none;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  .btns {
    background: #db8130;
    color: #160d05;
    padding: 10px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }

  .btn-outline {
    border: 1px solid #1e1b4e;
    background: #fff;
    color: #1e1b4e;
  }
`;

export default InlineLayout;
