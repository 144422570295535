import { Dispatch, useEffect, useState } from "react";
import { useAppContext } from "context/AppContext";
import ContentLayout from "../../layouts/ContentLayout";
import styled from "styled-components";
import { toCsv } from "utils/csv";
import ExportImg from "../../assets/svg/exportFile.svg";
import toastr from "toastr";
import ReconciliationService from "../../services/ReconciliationService";

interface Report {
  sales_rep_name: string;
  sales_rep_e_code: string;
  outlet_login: number;
  outlet_with_first_order: number;
  outlet_with_three_more_orders: number;
  order_created: number;
  order_pending: number;
  order_awaiting: number;
  order_delivered: number;
  order_failed: number;
  delivered_within_24hrs: number;
}

const Report = () => {
  const { state, dispatch } = useAppContext();
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [reportName, setReportName] = useState("");
  const [allReportName, setAllReportName] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleExport = (e: any) => {
    e.preventDefault();

    const fetchData = async () => {
      try {
        dispatch({ type: "data-loading" });
        setIsLoading(true);
        const { data: d } = await ReconciliationService.getAllReport(
          startDate,
          endDate
          // reportName
        );

        const data = d.data;

        if (data.length < 1) {
          toastr.info("No data available");
          return;
        }

        const csvData = data.map((item: Report) => {
          return {
            "Sales Rep Name": item.sales_rep_name,
            "Sales rep e-code": item.sales_rep_e_code,
            "Outlet Login": item.outlet_login,
            "Outlet with first order": item.outlet_with_first_order,
            "Outlet with > 3 Orders": item.outlet_with_three_more_orders,
            "Order Created": item.order_created,
            "Order Pending": item.order_pending,
            "Order Awaiting": item.order_awaiting,
            "Order Failed": item.order_failed,
            "Order Delivered": item.order_delivered,
            "Order Delivered within 24 Hours": item.delivered_within_24hrs,
          };
        });

        const csvText = toCsv(csvData);
        const blob = new Blob([csvText], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "Report.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        toastr.success(d.message);
        setIsLoading(false);
      } catch (error: any) {
        if (error.message === "Request failed with status code 401") {
          toastr.error("You are not authorize to perform this operation");
        } else {
          toastr.error(error.message);
        }
        setIsLoading(false);
      } finally {
        dispatch({ type: "data-loaded" });
        setIsLoading(false);
      }
    };

    fetchData();
  };
  return (
    <ContentLayout titleText="Report-Management  Module" subText="">
      <ContentWrapper>
        <BoxContainer>
          <div className="head">
            <h3>Export Report</h3>
          </div>
          <div className="details">
            <div className="details-icon">
              <img src={ExportImg} alt="icon" />
            </div>
            <p className="details-txt">
              Select a date and export the data as a .CSV file
            </p>
            <div className="export-form">
              <div className="int-group">
                <div className="datee">
                  <label>Start date</label>
                  <input
                    type="date"
                    required
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    max={new Date().toISOString().split("T")[0]}
                  />
                </div>
                <div className="datee">
                  <label>End date</label>
                  <input
                    type="date"
                    required
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    max={new Date().toISOString().split("T")[0]}
                  />
                </div>
              </div>
              <div className="int-single">
                <label>Report Name</label>
                <select
                  className="filter-select"
                  onChange={(e) => setReportName(e.target.value)}
                >
                  {/* <option value="all">ALL</option>
                  {allReportName &&
                    allReportName?.map((name: any) => (
                      <option key={name.id} value={name.name}>
                        {name.name}
                      </option>
                    ))} */}
                  <option value="saleRepOrderFulfilment">
                    Sales rep order fulfilment
                  </option>
                </select>
              </div>

              <div className="btn-container">
                <button
                  onClick={handleExport}
                  disabled={isLoading ? true : false}
                >
                  {isLoading ? "Loading...." : "Export"}
                </button>
              </div>
            </div>
          </div>
        </BoxContainer>
      </ContentWrapper>
    </ContentLayout>
  );
};

const ContentWrapper = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const BoxContainer = styled.div`
  max-width: 450px;
  width: 100%;
  background: #fff;
  padding: 50px;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;

  .head {
    padding-bottom: 20px;
    text-align: center;
  }
  .head h3 {
    color: #262262;
    font-weight: normal;
  }
  .details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    max-width: 500px;
    width: 100%;
  }
  .details-icon {
    width: 100px;
  }
  .details-txt {
    text-align: center;
    margin-top: 15px;
  }
  .export-form {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
  }
  .int-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .int-group input {
    width: 48%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  .int-single {
    width: 100%;
  }
  .int-single label {
    display: block;
    color: #888;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .int-single select,
  .int-single input {
    width: 100%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }

  .datee label {
    display: block;
    color: #888;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .datee input {
    width: 100%;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .btn-container button {
    background: #1e1b4e;
    width: 250px;
    color: #fff;
    padding: 10px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }
  .btn-container button:disabled {
    background: #d4d3e0;
    cursor: auto;
  }
`;

export default Report;
