import styled from "styled-components";
import { OutletManagement } from "./OutletManagement";

export type ItemType = {
  id: number;
  employee_id: number;
  route_id: string;
  outletname: string;
  depots: string;
  region: string;
  customer_code: string;
  phoneNumber: string;
  urno: string;
  createdAt: string;
};
export type ReodaItemType = {
  id: number;
  employee_id: number;
  route_id: string;
  outletname: string;
  businesName: string;
  depots: string;
  region: string;
  customer_code: string;
  phoneNumber: string;
  urno: string;
  createdAt: string;
};
export type ActiveItemType = {
  reps: number;
  outletName: string;
  businessStore: string;
  depot: string;
  region: string;
  phoneNumber: string;
  urNo: number;
};

const ContentWrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  position: relative;

  .chart-container {
    padding: 20px 0;
  }

  .outlets {
    width: 31.125em;
    height: 10.125em;
    background-color: #fff;
    margin: 0 auto;
    border-radius: 8px;
    padding: 24px 40px;
  }
  .section-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #262262;
    display: grid;
    place-items: center;
    margin-top: 5px;

    img {
      object-fit: contain;
    }
  }
  .summation {
    display: flex;
    flex-direction: column;

    .total-amount {
      margin-top: auto;
      color: #262262;
      font-size: 23px;
      font-weight: 600;
    }
  }
`;

export { OutletManagement, ContentWrapper };
