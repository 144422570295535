import { Dispatch, useEffect, useState } from "react";
import { useAppContext } from "context/AppContext";
import ContentLayout from "../../layouts/ContentLayout";
import styled from "styled-components";
import { toCsv } from "utils/csv";
import ExportImg from "../../assets/svg/exportFile.svg";
import toastr from "toastr";
import ReconciliationService from "../../services/ReconciliationService";
import BannerService from "../../services/BannerService";

const ShopKeeperExport = () => {
  const { state, dispatch } = useAppContext();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [depot, setDepot] = useState("ALL");
  const [allDepots, setAllDepots] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const {
          data: {
            data: { depots },
          },
        } = await BannerService.getAllDepots();

        setAllDepots(depots);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch({ type: "data-loaded" });
      }
    }
    fetchData();
  }, []);

  const handleExport = (e: any) => {
    e.preventDefault();
    if (new Date(endDate) < new Date(startDate)) {
      toastr.error("End date cannot be less than start date");
      return;
    }

    const fetchData = async () => {
      try {
        dispatch({ type: "data-loading" });
        const { data: d } =
          await ReconciliationService.getAllReconciliationOrder(
            "",
            startDate,
            endDate,
            depot
          );
        const data = d.response;

        if (data?.length < 1) {
          toastr.info("No data available");
          setDepot("ALL");
          return;
        }

        const jsonData = JSON.stringify(data, null, 2);

        // Create a blob with the JSON data
        const blob = new Blob([jsonData], { type: "application/json" });

        // Create a link element
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "data.json";

        // Append the link to the body and click it
        document.body.appendChild(a);
        a.click();

        // Remove the link from the body
        document.body.removeChild(a);
        toastr.success(d.message);
        setDepot("ALL");
        console.log("chnaged");
      } catch (error: any) {
        if (error.message === "Request failed with status code 401") {
          toastr.error("You are not authorize to perform this operation");
        } else {
          toastr.error(error.message);
        }
      } finally {
        dispatch({ type: "data-loaded" });
        setEndDate("");
        setStartDate("");
        setDepot("ALL");
      }
    };

    fetchData();
  };

  return (
    <ContentLayout titleText="Reconciliaition  Module" subText="">
      <ContentWrapper>
        <BoxContainer>
          <div className="head">
            <h3>Export Promo Order</h3>
          </div>
          <div className="details">
            <div className="details-icon">
              <img src={ExportImg} alt="icon" />
            </div>
            <p className="details-txt">
              Select a date and export the data as a .JSON file
            </p>
            <div className="export-form">
              <div className="int-group">
                <div className="datee">
                  <label>Start date</label>
                  <input
                    type="date"
                    required
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    max={new Date().toISOString().split("T")[0]}
                  />
                </div>
                <div className="datee">
                  <label>End date</label>
                  <input
                    type="date"
                    required
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    max={new Date().toISOString().split("T")[0]}
                  />
                </div>
              </div>
              <div className="int-single">
                <label>Depot</label>
                <select
                  className="filter-select"
                  value={depot}
                  onChange={(e) => setDepot(e.target.value)}
                >
                  {/* <option value="Depot" disabled selected hidden>
                    Depot
                  </option> */}
                  <option value="all">ALL</option>
                  {allDepots &&
                    allDepots.map((depot: any) => (
                      <option key={depot.id} value={depot.name}>
                        {depot.name}
                      </option>
                    ))}
                </select>
              </div>

              <div className="btn-container">
                <button
                  disabled={
                    startDate === "" || endDate === "" || depot === ""
                      ? true
                      : false
                  }
                  onClick={handleExport}
                >
                  Export
                </button>
              </div>
            </div>
          </div>
        </BoxContainer>
      </ContentWrapper>
    </ContentLayout>
  );
};

const ContentWrapper = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const BoxContainer = styled.div`
  max-width: 450px;
  width: 100%;
  background: #fff;
  padding: 50px;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;

  .head {
    padding-bottom: 20px;
    text-align: center;
  }
  .head h3 {
    color: #262262;
    font-weight: normal;
  }
  .details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    max-width: 500px;
    width: 100%;
  }
  .details-icon {
    width: 100px;
  }
  .details-txt {
    text-align: center;
    margin-top: 15px;
  }
  .export-form {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
  }
  .int-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .int-group input {
    width: 48%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  .int-single {
    width: 100%;
  }
  .int-single label {
    display: block;
    color: #888;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .int-single select {
    width: 100%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  .datee {
    width: 48%;
  }
  .datee label {
    display: block;
    color: #888;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .datee input {
    width: 100%;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .btn-container button {
    background: #1e1b4e;
    width: 250px;
    color: #fff;
    padding: 10px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }
  .btn-container button:disabled {
    background: #d4d3e0;
    cursor: auto;
  }
`;

export default ShopKeeperExport;
