import {
  Dispatch,
  FocusEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { NavLink, Outlet, useMatch } from "react-router-dom";
import styled from "styled-components";
import filterIcon from "../../assets/svg/filter.svg";
import chevronDown from "assets/svg/chevron-down.svg";
import { toCsv } from "utils/csv";
import { Button } from "components/Button";
import { Checkbox } from "components/CheckBox";

type NavItem = {
  link: string;
  linkTo: string;
};

type NavTab = {
  navItems?: NavItem[];
  data?: { createdAt: string }[];
  text?: never;
  file?: string;
  canFilter?: boolean;
  allDepots?: any;
  setByRegion?: any;
  setByStatus?: Dispatch<SetStateAction<string>>;
  setByAdsType?: Dispatch<SetStateAction<string>>;
  setByDepots?: any;
  setBySpecific?: Dispatch<SetStateAction<string>>;
  setByCustomStart?: Dispatch<SetStateAction<string>>;
  setByCustomEnd?: Dispatch<SetStateAction<string>>;
  setWillFilter?: Dispatch<SetStateAction<string>>;
};
type NavText = {
  text: string;
  navItems?: never;
  data?: { createdAt: string }[];
  file?: string;
  canFilter?: boolean;
  allDepots?: any;
  setByRegion?: Dispatch<SetStateAction<string>>;
  setByStatus?: Dispatch<SetStateAction<string>>;
  setByAdsType?: Dispatch<SetStateAction<string>>;
  setByDepots?: any;
  setBySpecific?: Dispatch<SetStateAction<string>>;
  setByCustomStart?: Dispatch<SetStateAction<string>>;
  setByCustomEnd?: Dispatch<SetStateAction<string>>;
  setWillFilter?: Dispatch<SetStateAction<string>>;
};

type TableHeaderProps = NavTab | NavText;

interface Region {
  value: string;
  region: string;
  checked?: boolean;
}

interface Status {
  value: string;
  status: string;
  checked?: boolean;
}

interface AdsType {
  type: string;
  status: string;
  checked?: boolean;
}

interface Depots {
  value: string;
  name: string;
  checked?: boolean;
}

const allRegions: Region[] = [
  {
    value: "lg",
    region: "LG",
  },
  {
    value: "mb",
    region: "MB",
  },
  {
    value: "sw",
    region: "SW",
  },
  {
    value: "se",
    region: "SE",
  },
  {
    value: "nt",
    region: "NT",
  },
];

const adStatus: Status[] = [
  {
    value: "active",
    status: "Active",
  },
  {
    value: "inActive",
    status: "Not Active",
  },
];

const adType: AdsType[] = [
  {
    type: "promotionalAds",
    status: "Promotional Ads",
  },
  {
    type: "bundleAds",
    status: "Bundle Ads",
  },
];

export const DynamicTab = ({
  navItems,
  data,
  text,
  file,
  canFilter = true,
  allDepots,
  setByRegion,
  setByStatus,
  setByAdsType,
  setByDepots,
  setBySpecific,
  setByCustomStart,
  setByCustomEnd,
  setWillFilter,
}: TableHeaderProps) => {
  const [csvText, setText] = useState("");
  const [fileName, setFileName] = useState("");
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [inputType, setInputType] = useState({
    allTime: false,
    specific: false,
    custom: false,
  });
  const [value, setValue] = useState("");
  const [filter, setFilter] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isPop, setIsPop] = useState(false);
  const popRef = useRef<HTMLDivElement>(null);
  const selectRef = useRef<HTMLDivElement>(null);
  const filterRef = useRef<HTMLButtonElement>(null);
  const endDateRef = useRef<HTMLInputElement>(null);
  const [isRegionSelectOpen, setIsRegionSelectOpen] = useState(false);
  const [isStatusSelectOpen, setIsStatusSelectOpen] = useState(false);
  const [isTypeSelectOpen, setIsTypeSelectOpen] = useState(false);
  const [isDepotSelectOpen, setIsDepotSelectOpen] = useState(false);
  const [isDateSelectOpen, setIsDateSelectOpen] = useState(false);
  const [checkAllRegion, setCheckAllRegion] = useState(false);
  const [checkboxesRegion, setCheckboxesRegion] = useState<Region[]>(
    allRegions.map((region) => ({ ...region, checked: false }))
  );
  const [checkAllStatus, setCheckAllStatus] = useState(false);
  const [checkboxesStatus, setCheckboxesStatus] = useState<Status[]>(
    adStatus.map((status) => ({ ...status, checked: false }))
  );
  const [checkAllType, setCheckAllType] = useState(false);
  const [checkboxesType, setCheckboxesType] = useState<AdsType[]>(
    adType.map((type) => ({ ...type, checked: false }))
  );
  const [checkAllDepot, setCheckAllDepot] = useState(false);
  const [checkboxesDepot, setCheckboxesDepot] = useState<Depots[]>();

  useEffect(() => {
    if (allDepots) {
      let tempData = allDepots.map((data: any) => {
        return {
          value: data,
          name: data,
          checked: false,
        };
      });
      setCheckboxesDepot(tempData);
    }
  }, [allDepots]);

  useEffect(() => {
    if (data?.length! > 0 && data !== undefined && file !== undefined) {
      setText(toCsv(data));
      setFileName(file);
    }
  }, [data, file]);

  useEffect(() => {
    if (setBySpecific) setBySpecific(value);
  }, [value]);

  useEffect(() => {
    if (setByCustomStart) setByCustomStart(startDate);
    if (setByCustomEnd) setByCustomEnd(endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    let handler = (e: MouseEvent) => {
      if (
        !popRef.current?.contains(e!.target as Node) &&
        !selectRef.current?.contains(e!.target as Node)
      )
        setIsPop(false);
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    let handler = (e: MouseEvent) => {
      if (
        !selectRef.current?.contains(e!.target as Node) &&
        !isPop &&
        !filterRef.current?.contains(e!.target as Node)
      )
        setOpen(false);
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const onOptionClicked = (value: string) => {
    setSelected(value);
  };

  const onRegionClicked = (value: any) => {
    setFilter(!filter);
    setIsPop(false);
    setOpen(false);
    if (setWillFilter) setWillFilter(String(Math.random()));
  };

  const onInputBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
    const { name } = e.target;
    setInputType({ ...inputType, [name]: false });
  };

  const toggle = (value: boolean) => {
    setIsSelectOpen(value);
  };

  const toggles = (
    value: boolean,
    setter: Dispatch<SetStateAction<boolean>>
  ) => {
    setter(value);
  };

  function runCleanup() {
    setValue("");
    setStartDate("");
    setEndDate("");
  }

  const handleCancelReset = () => {
    //Reset Region checkbox
    let regionTempData = checkboxesRegion.map((checkbox) => ({
      ...checkbox,
      checked: false,
    }));
    setCheckboxesRegion(regionTempData);
    //Reset Depot checkbox
    let depotTempData = checkboxesDepot?.map((checkbox) => ({
      ...checkbox,
      checked: false,
    }));
    setCheckboxesDepot(depotTempData);
    //Reset Status checkbox
    let statusTempData = checkboxesStatus.map((checkbox) => ({
      ...checkbox,
      checked: false,
    }));
    setCheckboxesStatus(statusTempData);
    //Reset Type checkbox
    let typeTempData = checkboxesType.map((checkbox) => ({
      ...checkbox,
      checked: false,
    }));
    setCheckboxesType(typeTempData);

    //Reset Schedule
    setStartDate("");
    setEndDate("");
    if (setByRegion) setByRegion("");
    if (setByStatus) setByStatus("");
    if (setByAdsType) setByAdsType("");
    if (setByCustomEnd) setByCustomEnd("");
    if (setByCustomStart) setByCustomStart("");
    if (setByRegion) setByDepots("");
    setFilter(false);
    setIsPop(false);
    setOpen(false);
    setValue("");
    if (setWillFilter) setWillFilter(String(Math.random()));
  };

  // Function to handle click on "Check All" checkbox for Region
  const handleCheckAllRegion = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newState = event.target.checked;
    setCheckAllRegion(newState);
    const updatedCheckboxes = checkboxesRegion.map((checkbox) => ({
      ...checkbox,
      checked: checkbox.value === "all" ? newState : false, // Uncheck other checkboxes if "Check All" is checked
    }));
    setCheckboxesRegion(updatedCheckboxes);
    // If "Check All" is selected, add all regions except "Check All" to selectedRegions
    if (newState) {
      setByRegion(
        allRegions
          .filter((region) => region.value !== "all")
          .map((region) => region.value)
      );
      setValue("regions");
    } else {
      setByRegion([]); // Clear selected regions if "Check All" is unchecked
    }
  };

  // Function to handle click on "Check All" checkbox for Status
  const handleCheckAllStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newState = event.target.checked;
    setCheckAllStatus(newState);
    const updatedCheckboxes = checkboxesStatus.map((checkbox) => ({
      ...checkbox,
      checked: checkbox.value === "all" ? newState : false, // Uncheck other checkboxes if "Check All" is checked
    }));
    setCheckboxesStatus(updatedCheckboxes);
    // If "Check All" is selected, set selectedStatus to empty string
    if (setByStatus) setByStatus(newState ? "" : "");
  };

  // Function to handle click on "Check All" checkbox for Type
  const handleCheckAllType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newState = event.target.checked;
    setCheckAllType(newState);
    const updatedCheckboxes = checkboxesType.map((checkbox) => ({
      ...checkbox,
      checked: checkbox.type === "all" ? newState : false, // Uncheck other checkboxes if "Check All" is checked
    }));
    setCheckboxesType(updatedCheckboxes);
    // If "Check All" is selected, set selectedStatus to empty string
    if (setByAdsType) setByAdsType(newState ? "" : "");
  };

  // Function to handle click on "Check All" checkbox for Depot
  const handleCheckAllDepot = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newState = event.target.checked;
    setCheckAllDepot(newState);
    const updatedCheckboxes = checkboxesDepot?.map((checkbox) => ({
      ...checkbox,
      checked: checkbox.value === "all" ? newState : false, // Uncheck other checkboxes if "Check All" is checked
    }));
    setCheckboxesDepot(updatedCheckboxes);
    // If "Check All" is selected, add all regions except "Check All" to selectedRegions
    if (newState) {
      setByDepots(
        allRegions
          .filter((region) => region.value !== "all")
          .map((region) => region.value)
      );
      setValue("depots");
    } else {
      setByDepots([]); // Clear selected regions if "Check All" is unchecked
      setValue("");
    }
  };

  // Function to handle click on individual checkboxes for Region
  const handleCheckboxChangeRegion = (value: string) => {
    const updatedCheckboxes = checkboxesRegion.map((checkbox) => {
      if (checkbox.value === value) {
        return { ...checkbox, checked: !checkbox.checked };
      }
      return checkbox;
    });
    setCheckboxesRegion(updatedCheckboxes);
    // Uncheck "Check All" checkbox if any other checkbox is checked
    setCheckAllRegion(false);
    // Update selected regions based on checkboxes state
    const selected = updatedCheckboxes
      .filter((checkbox) => checkbox.checked && checkbox.value !== "all")
      .map((checkbox) => checkbox.value);
    setByRegion(selected);
    setValue("regions");
  };

  // Function to handle click on individual checkboxes for Status
  const handleCheckboxChangeStatus = (value: string) => {
    const updatedCheckboxes = checkboxesStatus.map((checkbox) => {
      if (checkbox.value === value) {
        return { ...checkbox, checked: true };
      }
      return { ...checkbox, checked: false };
    });
    setCheckboxesStatus(updatedCheckboxes);
    // Uncheck "Check All" checkbox if any other checkbox is checked
    setCheckAllStatus(false);
    // Update selected status based on checkboxes state
    const selected = updatedCheckboxes
      .filter((checkbox) => checkbox.checked && checkbox.value !== "all")
      .map((checkbox) => checkbox.value);
    setValue("status");

    if (setByStatus) setByStatus(selected.length === 1 ? selected[0] : ""); // Set selected status to the only checked value or empty string
  };

  // Function to handle click on individual checkboxes for Type
  const handleCheckboxChangeType = (value: string) => {
    const updatedCheckboxes = checkboxesType.map((checkbox) => {
      if (checkbox.type === value) {
        return { ...checkbox, checked: true };
      }
      return { ...checkbox, checked: false };
    });
    setCheckboxesType(updatedCheckboxes);
    // Uncheck "Check All" checkbox if any other checkbox is checked
    setCheckAllType(false);
    // Update selected status based on checkboxes state
    const selected = updatedCheckboxes
      .filter((checkbox) => checkbox.checked && checkbox.type !== "all")
      .map((checkbox) => checkbox.type);
    setValue("type");

    if (setByAdsType) setByAdsType(selected.length === 1 ? selected[0] : ""); // Set selected status to the only checked value or empty string
  };

  // Function to handle click on individual checkboxes for Depots
  const handleCheckboxChangeDepot = (value: string) => {
    const updatedCheckboxes = checkboxesDepot?.map((checkbox) => {
      if (checkbox.value === value) {
        return { ...checkbox, checked: !checkbox.checked };
      }
      return checkbox;
    });
    setCheckboxesDepot(updatedCheckboxes);
    // Uncheck "Check All" checkbox if any other checkbox is checked
    setCheckAllDepot(false);
    // Update selected regions based on checkboxes state
    const selected: any = updatedCheckboxes
      ?.filter((checkbox) => checkbox.checked && checkbox.value !== "all")
      .map((checkbox) => checkbox.value);
    setByDepots(selected);
    setValue("depots");
    if (selected.length < 1) {
      setValue("");
    }
  };

  const handleScheduleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate("");
    setEndDate("");
    setValue(event.target.value);
  };

  return (
    <>
      <Nav>
        <div className="flex tab">
          {navItems !== undefined && (
            <ul className="flex tab-nav">
              {navItems.map((nav, index) => (
                <li key={index}>
                  <NavLink onClick={() => runCleanup()} to={nav.linkTo}>
                    {nav.link}
                  </NavLink>
                </li>
              ))}
            </ul>
          )}
          {text !== undefined && <p className="nav-text">{text}</p>}
          <div className="flex">
            <button
              ref={filterRef}
              onClick={() => setOpen(!open)}
              className={`flex btn btn-default-ghost pointer ${
                canFilter ? "" : "no-display"
              }`}
            >
              <div>
                <img src={filterIcon} alt="filter" />
              </div>
              <span className="fw-600 fs-100">Filter</span>
            </button>

            <a
              className="btn export-btn pointer"
              download={fileName}
              href={`data:text/csv;charset=utf-8,${encodeURIComponent(
                csvText
              )}`}
            >
              <span className="fw-600 fs-100">Export</span>
            </a>
          </div>
        </div>
        {open && (
          <aside ref={selectRef} className="">
            {/* Region */}
            {setByRegion && (
              <>
                <div
                  className="flex filter-options pointer"
                  onClick={() =>
                    toggles(!isRegionSelectOpen, setIsRegionSelectOpen)
                  }
                >
                  <p>Region</p>
                  <span className={isRegionSelectOpen ? "rotate" : ""}>
                    <img src={chevronDown} alt="" />
                  </span>
                </div>
                <div
                  className={` ${
                    isRegionSelectOpen ? "open open-nospace" : ""
                  }`}
                >
                  <div
                    className={`${
                      isRegionSelectOpen ? "grid grid-nospace" : "no-display"
                    }`}
                  >
                    <div className="item-container">
                      <div className="item-item">
                        <Checkbox
                          checked={checkAllRegion}
                          onChange={handleCheckAllRegion}
                        />
                        <label>All</label>
                      </div>
                      {checkboxesRegion.map((checkbox) => (
                        <div className="item-item" key={checkbox.value}>
                          <Checkbox
                            checked={checkbox.checked}
                            onChange={() =>
                              handleCheckboxChangeRegion(checkbox.value)
                            }
                          />

                          <label>{checkbox.region}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* Status */}
            {setByStatus && (
              <>
                <div
                  className="flex filter-options pointer"
                  onClick={() =>
                    toggles(!isStatusSelectOpen, setIsStatusSelectOpen)
                  }
                >
                  <p>Ads status</p>
                  <span className={isStatusSelectOpen ? "rotate" : ""}>
                    <img src={chevronDown} alt="" />
                  </span>
                </div>
                <div
                  className={` ${
                    isStatusSelectOpen ? "open open-nospace" : ""
                  }`}
                >
                  <div
                    className={`${
                      isStatusSelectOpen ? "grid grid-nospace" : "no-display"
                    }`}
                  >
                    <div className="item-container">
                      <div className="item-item">
                        <Checkbox
                          checked={checkAllStatus}
                          onChange={handleCheckAllStatus}
                        />
                        <label>All</label>
                      </div>
                      {checkboxesStatus.map((checkbox) => (
                        <div className="item-item" key={checkbox.value}>
                          <Checkbox
                            checked={checkbox.checked}
                            onChange={() =>
                              handleCheckboxChangeStatus(checkbox.value)
                            }
                          />

                          <label>{checkbox.status}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* Target Depots */}
            {setByDepots && (
              <>
                <div
                  className="flex filter-options pointer"
                  onClick={() =>
                    toggles(!isDepotSelectOpen, setIsDepotSelectOpen)
                  }
                >
                  <p>Target Depots</p>
                  <span className={isDepotSelectOpen ? "rotate" : ""}>
                    <img src={chevronDown} alt="" />
                  </span>
                </div>
                <div
                  className={` ${
                    isDepotSelectOpen ? "open open-nospace overflowY" : ""
                  }`}
                >
                  <div
                    className={`${
                      isDepotSelectOpen ? "grid grid-nospace" : "no-display"
                    }`}
                  >
                    <div className="item-container">
                      <div className="item-item">
                        <Checkbox
                          checked={checkAllDepot}
                          onChange={handleCheckAllDepot}
                        />
                        <label>All</label>
                      </div>
                      {checkboxesDepot?.map((checkbox, index) => (
                        <div className="item-item" key={index}>
                          <Checkbox
                            checked={checkbox.checked}
                            onChange={() =>
                              handleCheckboxChangeDepot(checkbox.value)
                            }
                          />

                          <label>{checkbox.name}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* Type */}
            {setByAdsType && (
              <>
                <div
                  className="flex filter-options pointer"
                  onClick={() =>
                    toggles(!isTypeSelectOpen, setIsTypeSelectOpen)
                  }
                >
                  <p>Ads type</p>
                  <span className={isTypeSelectOpen ? "rotate" : ""}>
                    <img src={chevronDown} alt="" />
                  </span>
                </div>
                <div
                  className={` ${isTypeSelectOpen ? "open open-nospace" : ""}`}
                >
                  <div
                    className={`${
                      isTypeSelectOpen ? "grid grid-nospace" : "no-display"
                    }`}
                  >
                    <div className="item-container">
                      <div className="item-item">
                        <Checkbox
                          checked={checkAllType}
                          onChange={handleCheckAllType}
                        />
                        <label>All</label>
                      </div>
                      {checkboxesType.map((checkbox) => (
                        <div className="item-item" key={checkbox.type}>
                          <Checkbox
                            checked={checkbox.checked}
                            onChange={() =>
                              handleCheckboxChangeType(checkbox.type)
                            }
                          />

                          <label>{checkbox.type}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* Date */}
            <div
              className="flex filter-options pointer"
              onClick={() => toggles(!isDateSelectOpen, setIsDateSelectOpen)}
            >
              <p>Schedule</p>
              <span className={isDateSelectOpen ? "rotate" : ""}>
                <img src={chevronDown} alt="" />
              </span>
            </div>
            <div className={` ${isDateSelectOpen ? "open open-nospace" : ""}`}>
              <div
                className={`${
                  isDateSelectOpen ? "grid grid-nospace" : "no-display"
                }`}
              >
                <div className="item-container">
                  <div className="item-item">
                    <Checkbox
                      //   checked={checkAllType}
                      onChange={handleScheduleChange}
                    />
                    <label>All</label>
                  </div>
                  <div className="datee">
                    <label>Start date</label>
                    <input
                      type="date"
                      required
                      value={startDate}
                      onChange={(e) => {
                        setStartDate(e.target.value);
                        setValue(endDate || startDate ? e.target.value : "");
                      }}
                      // max={new Date().toISOString().split("T")[0]}
                    />
                  </div>
                  <div className="datee">
                    <label>End date</label>
                    <input
                      type="date"
                      required
                      value={endDate}
                      onChange={(e) => {
                        setEndDate(e.target.value);
                        setValue(endDate || startDate ? e.target.value : "");
                      }}
                      // max={new Date().toISOString().split("T")[0]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-container">
              <Button
                className={`${value ? "" : "no-display"}`}
                onClick={() => {
                  setFilter(!filter);
                  setIsPop(false);
                  setOpen(false);
                  if (setWillFilter) setWillFilter(String(Math.random()));
                }}
              >
                Apply
              </Button>
              <button
                className={`inline-btn ${value ? "" : "no-display"}`}
                onClick={() => {
                  setFilter(!filter);
                  setIsPop(false);
                  setOpen(false);
                  handleCancelReset();
                }}
              >
                Clear result
              </button>
            </div>
          </aside>
        )}
      </Nav>
      <Outlet
        context={[
          toCsv,
          setText,
          setFileName,
          value,
          startDate,
          endDate,
          filter,
        ]}
      />
    </>
  );
};

const Nav = styled.nav`
  position: relative;

  .rotate {
    transform: translateY(-50%) rotate(0.5turn);
  }
  .tab {
    padding: 1em 4em;
    align-items: center;
    justify-content: space-between;
    background-color: hsla(var(--clr-light));
  }
  .tab-nav {
    --gap: 4rem;
    align-items: center;
  }
  .tab-nav .active {
    color: hsla(var(--clr-blue-tint-100));
  }
  .tab-nav .active::after {
    position: absolute;
    content: "";
    width: 100%;
    border-bottom: 2px solid hsla(var(--clr-blue-tint-100));
    padding-bottom: 4em;
  }
  .tab-nav li {
    transition: all 0.3s ease-in-out;
  }

  .tab-nav a {
    color: hsla(var(--clr-black-tint-200));
    position: relative;
    display: grid;
    align-items: center;
  }
  .tab-nav > * > a:hover::after {
    position: absolute;
    content: "";
    color: hsla(var(--clr-blue-tint-100));
    width: 100%;
    border-bottom: 2px solid hsla(var(--clr-blue-tint-100));
    padding-bottom: 4em;
  }
  .nav-text {
    color: #3c3872;
    font-size: 18px;
  }
  button {
    --gap: 0.3rem;
    align-items: center;
    justify-content: space-between;
    padding: 0.3em 1em;
  }
  .export-btn {
    background-color: #262262;
    color: #fff;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }
  aside {
    background-color: hsla(var(--clr-light));
    position: absolute;
    right: 5%;
    width: max-content;
    height: max-content;
    place-items: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    padding: 1.5em;
    z-index: 123;
    transition-duration: 500ms;
  }
  .filter-options {
    align-items: center;
    justify-content: space-between;
    width: 12em;
    padding: 0.625em;

    span {
      transition: transform 0.4s;
    }
  }
  .select {
    width: 12em;
    background: hsla(var(--clr-light));
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    max-height: 0;
    opacity: 0;

    transition-duration: 500ms;

    input {
      outline: transparent solid 2px;
      border: none;
      width: 100%;
      padding: 0.7em;
    }
    input::placeholder {
      color: hsla(var(--clr-dark));
      opacity: 1;
    }
    input:hover {
      background: hsla(var(--clr-yellow-tint-900));
    }
  }

  .open {
    padding-block: 1em;
    max-height: 100%;
    opacity: 1;
  }

  .select ul {
    --gap: 0;
  }

  .select li {
    padding: 0.7em;
  }

  .select li:hover {
    background: hsla(var(--clr-yellow-tint-900));
  }

  .all {
    padding: 0.7em;
  }
  .pop-up {
    width: max-content;
    height: 30vh;
    margin: auto;
    padding: 0.5em 1em;
    position: absolute;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    inset: 0;
    background: hsla(var(--clr-light));
    z-index: 99;

    input {
      padding: 0.625em;
      border: 1px solid hsla(var(--clr-border));
      border-radius: 2px;
      width: 12em;
    }
    .custom {
      justify-self: end;
    }
  }
  .jLQKkL .grid {
    gap: 0 !important;
  }

  .filter-select {
    border: 1px solid #707070;
    border-radius: 6px;
    width: 190px;
    height: 50px;
    color: #707070;
    font-size: 16px;
    margin-bottom: 30px;
    padding-left: 8px;
    box-sizing: border-box;
    background: #ffffff;
  }
  .item-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .item-item {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .btn-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 120px;
    width: 100%;
    align-items: center;
    margin: 0 auto;
    margin-top: 10px;
  }
  .inline-btn {
    min-height: 34px;
    margin-bottom: 0;
    line-height: 24px;
    text-align: center;
    white-space: nowrap;
    touch-action: manipulation;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 5px;
    color: hsla(var(--clr-blue-tint-050));
  }
  .overflowY {
    height: 200px;
    overflow-y: auto;
  }
  .datee {
    width: 80%;
  }
  .datee label {
    display: block;
    color: #888;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .datee input {
    width: 100%;
  }
`;
