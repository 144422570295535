import { useState, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import ImageSvg from "../....//assets/svg/image.svg";

interface CreateBundleFormProps {
  setFormView: Dispatch<SetStateAction<String>>;
}

export const CreateBundleForm: React.FC<CreateBundleFormProps> = ({
  setFormView,
}) => {
  const [nextForm, setnextForm] = useState(false);
  const [selectedPromo, setSelectedPromo] = useState("");

  const handleSelectChange = (event: any) => {
    setSelectedPromo(event.target.value);
  };
  const handleCreateForm = (e: any) => {
    e.preventDefault();
    setnextForm(true);
    setFormView(selectedPromo);
  };
  return (
    <Form>
      <form className="form-container" onSubmit={handleCreateForm}>
        <h2>Create Ads</h2>
        <div className="form-control">
          <div className="int-single">
            <select value={selectedPromo} onChange={handleSelectChange}>
              <option value=" disabled selected  hidden">Ads type</option>
              <option value="normal">Promotional ads</option>
              <option value="bundle">Bundle ads</option>
            </select>
          </div>
          <div className="btn-container">
            <button
              disabled={
                selectedPromo === "bundle"
                  ? false
                  : selectedPromo === "normal"
                  ? false
                  : true
              }
            >
              Confirm Promo
            </button>
          </div>
        </div>
      </form>
    </Form>
  );
};

const Form = styled.div`
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-container h2 {
    color: #262262;
    font-weight: 500;
    margin-bottom: 30px;
  }
  .create {
    display: none;
  }
  .form-control {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .int-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .int-group select {
    width: 48%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  .int-single select {
    width: 100%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .btn-container button {
    background: #1e1b4e;
    width: 200px;
    color: #fff;
    padding: 10px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }
  .btn-container button:disabled {
    background: #d4d3e0;
    cursor: auto;
  }
  .int-single input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100px;
    z-index: 102;
    opacity: 0;
  }
  .int-single label {
    display: block;
    color: #888;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .int-single p {
    font-size: 0.8rem;
    color: #999;
  }
  .int-single ._label {
    background: #ececec;
    width: 100%;
    height: 50px;
    border-top: 2px dashed #d4d4d4;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lrg {
    height: 100px !important;
  }
  .int-single ._label p {
    font-size: 0.9rem;
    color: #262262;
    cursor: pointer;
  }
`;
