import ButtonSpinner from "components/Loaders/ButtonSpinner";
import { useAppContext } from "context/AppContext";
import React, { SetStateAction, useState } from "react";
import styled from "styled-components";
import ProductService from "services/ProductService";
import { ItemType } from ".";

interface ModalProps {
  item: ItemType;
  display: boolean;
  setIsModal: React.Dispatch<SetStateAction<boolean>>;
}

export const ActionModal: React.FC<ModalProps> = ({
  item,
  display,
  setIsModal,
}) => {
  const { dispatch } = useAppContext();
  async function onDisable(id: string, isDeleted: boolean) {
    const status = isDeleted ? "activate" : "deactivate";
    try {
      dispatch({ type: "action-loading" });
      const {
        data: { statusCode, data },
      } = await ProductService.activateProduct(id, status);
      if (statusCode === 200 && data) item.isDeleted = !isDeleted;
    } catch (error) {
    } finally {
      dispatch({ type: "action-loaded" });
    }
  }

  if (!display) return null;
  return (
    <>
      <StyledOverlay />
      <StyledModal className="modal">
        <Close className="pointer close" onClick={() => setIsModal(false)} />
        <p
          className="fs-100 fw-400 text-black-900 pointer"
          onClick={() => onDisable(item.product_code, item.isDeleted)}
        >
          {item.isDeleted ? "Enable SKU" : "Disable SKU"}
        </p>
      </StyledModal>
    </>
  );
};

const Close = ({ ...props }) => {
  const { state } = useAppContext();
  const isLoading = state?.isActionLoading;

  return (
    <span {...props}>
      {isLoading ? (
        <ButtonSpinner />
      ) : (
        <svg width="14" height="15" xmlns="http://www.w3.org/2000/svg">
          <path
            d="m11.596.782 2.122 2.122L9.12 7.499l4.597 4.597-2.122 2.122L7 9.62l-4.595 4.597-2.122-2.122L4.878 7.5.282 2.904 2.404.782l4.595 4.596L11.596.782Z"
            fill="#FFF"
            fillRule="evenodd"
          />
        </svg>
      )}
    </span>
  );
};

const StyledOverlay = styled.td`
  background-color: rgba(0, 0, 0, 0.6);
  inset: 0;
  position: fixed;
  transition: transform 600ms ease-in-out;
  z-index: 999;
`;

const StyledModal = styled.td`
  background-color: hsla(var(--clr-light));
  position: absolute;
  z-index: 999;
  right: 5%;
  top: 0;
  padding: 0.7em 0 !important;
  width: 9.375em;

  .close {
    position: absolute;
    top: -10px;
    right: -5%;
    z-index: 9;
    background-color: hsla(var(--clr-blue-tint-050));
    border-radius: 50%;
    height: 24px;
    width: 24px;
    display: inline-grid;
    place-items: center;
  }
  p {
    padding: 0.5em 1em;
    width: 100%;
    text-align: left;
    &:hover {
      background: hsla(var(--clr-yellow-tint-900));
    }
  }
`;
