import React, { useState, useEffect } from "react";
import Dropdown from "react-dropdown-select";
import styled from "styled-components";
import { useAppContext } from "context/AppContext";
import ProductService from "services/ProductService";
import { Checkbox } from "./CheckBox";

interface SelectFormProps {
  // setSelectedDepots: Dispatch<SetStateAction<[]>>;
  setSelectedRegions: any;
  regions: [];
  selectedRegions: [];
}
export const RegionSelect: React.FC<SelectFormProps> = ({
  setSelectedRegions,
  selectedRegions,
  regions,
}) => {
  const { dispatch } = useAppContext();
  const [options, setOptions] = useState<string[]>([]);
  const [exisitngRegions, setExistingRegions] = useState<any>();
  const [checkBox, setCheckBox] = useState(false);
  const [isSelectAllSelected, setIsSelectAllSelected] = useState(false);
  const [dropdownValue, setDropDownValue] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const {
          data: {
            data: { regions },
          },
        } = await ProductService.getAllRegions();

        setOptions(regions);
      } catch (error) {
        // Handle error
        console.log(error);
      } finally {
        dispatch({ type: "data-loaded" });
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    let filteredData;
    if (regions) {
      filteredData = options?.filter((obj: any) =>
        regions.includes(obj.name as never)
      );
    }

    if (regions) setSelectedRegions(filteredData);
    setExistingRegions(filteredData);
  }, [options]);

  const handleDropdownChange = (values: any) => {
    if (values.length < 1) {
      setSelectedRegions(values);
    }

    setDropDownValue(values);
  };

  const handleSelect = (e: any) => {
    e.preventDefault();
    setSelectedRegions(dropdownValue);
  };

  useEffect(() => {
    if (checkBox) {
      setSelectedRegions(options);
      setIsSelectAllSelected(true);
    } else {
      setIsSelectAllSelected(false);
      setSelectedRegions(dropdownValue);
    }
  }, [checkBox]);

  return (
    <RegionForm>
      <div className="drop-down">
        <Dropdown
          options={options}
          labelField="name"
          valueField="id"
          name="regions"
          // onChange={(values: any) => setSelectedDepots(values)}
          onChange={handleDropdownChange}
          placeholder="Choose Region"
          multi
          color="#8D8B8F"
          values={exisitngRegions}
          className="select_dropdown"
          searchable
          searchBy="name"
          required
          disabled={isSelectAllSelected}
        />
      </div>
      <div className="btnContainer">
        <button
          disabled={dropdownValue.length < 1 ? true : false}
          onClick={handleSelect}
        >
          Select
        </button>
      </div>
    </RegionForm>
  );
};

const RegionForm = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .drop-down {
    width: 68%;
  }
  .btnContainer {
    width: 30%;
    display: flex;
    align-items: center;
  }
  .btnContainer button {
    background: #db8130;
    color: #fff;
    padding: 8px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }
  .btnContainer button:disabled {
    background: #ecc5a2;
    cursor: auto;
  }
`;
