import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAppContext } from "context/AppContext";
import styled from "styled-components";
import toastr from "toastr";
import AppVersionService from "services/AppVersionService";
import { useForm, Controller } from "react-hook-form";
import SelectDepot from "./SelectDepot";
import { DepotSelect } from "components/DepotSelect";
import { RegionSelect } from "components/RegionSelect";
import { ItemType } from "../AppDistribution";
import UploadImg from "../../../../assets/svg/apk-upload.svg";

interface appVersionFormProps {
  handleModalClose: () => void;
  item?: ItemType;
  setWillFilter?: any;
}

const CreateAppVersion: React.FC<appVersionFormProps> = ({
  handleModalClose,
  item,
  setWillFilter,
}) => {
  const [appVersion, setAppVersion] = useState("");
  const [start_date, setStart_Date] = useState("");
  const [end_date, setEnd_Date] = useState("");
  const [depot, setDepot] = useState([]);
  const [selectedDepots, setSelectedDepots] = useState<[]>([]);
  const [selectedRegions, setSelectedRegions] = useState<[]>([]);
  const [releaseNote, setReleaseNote] = useState("");
  const [apkFile, setApkFile] = useState("");
  const [apkFileUrl, setApkFileUrl] = useState<any>();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const { state, dispatch } = useAppContext();

  const handleKeyPress = (currentValue: any) => (e: any) => {
    const char = e.key;
    if (!/[0-9.]/.test(char) && char !== "Backspace") {
      e.preventDefault();
      return;
    }

    if (char === "." && (currentValue.endsWith(".") || currentValue === "")) {
      e.preventDefault();
      return;
    }
  };
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileList = event.target.files;

    if (fileList && fileList.length > 0) {
      setApkFile(URL.createObjectURL(fileList[0]));
      setApkFileUrl(fileList[0]);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    var formData = new FormData(e.target as HTMLFormElement);

    const modifiedData = selectedDepots.map((obj: any) => {
      if (obj.name && typeof obj.name === "string") {
        const match = obj.name.match(/^[^(]+/); // Match characters before the opening parenthesis
        obj.name = match ? match[0].trim() : ""; // Trim whitespace and update the depot property
      }
      return obj;
    });
    const depotNames = modifiedData.map((data: any) => data.name);
    const newDepot: any = depotNames.map(
      (word) => word.charAt(0) + word.slice(1).toLowerCase()
    );

    const modifiedRegion = selectedRegions.map((obj: any) => {
      if (obj.name && typeof obj.name === "string") {
        const match = obj.name.match(/^[^(]+/); // Match characters before the opening parenthesis
        obj.name = match ? match[0].trim() : ""; // Trim whitespace and update the depot property
      }
      return obj;
    });
    const RegionNames = modifiedRegion.map((data: any) => data.name);
    const newRegion: any = RegionNames.map(
      (word) => word.charAt(0) + word.slice(1).toLowerCase()
    );

    formData.append("depots", newDepot);

    if (item) {
      let formData = {
        version: appVersion,
        depots: newDepot,
        regions: newRegion,
        releaseNote: releaseNote,
        startDate: start_date,
        endDate: end_date,
      };
      try {
        dispatch({ type: "action-loading" });
        setIsLoading(true);

        const {
          data: { code, message },
        } = await AppVersionService.updateAppVersion(item._id, formData);

        if (code === 200) {
          setIsLoading(false);
          toastr.success(message);

          item.version = appVersion;
          item.depots = newDepot;
          item.releaseNote = releaseNote;
          item.startDate = start_date;
          item.endDate = end_date;

          handleModalClose();
        }
      } catch (error: any) {
        setIsLoading(false);
        console.log(error);
        if (error.message === "Request failed with status code 503") {
          toastr.error("Version already exist");
        }
      } finally {
        dispatch({ type: "action-loaded" });
      }
    } else {
      try {
        dispatch({ type: "action-loading" });
        setIsLoading(true);
        const {
          data: { code, message },
        } = await AppVersionService.addAppVersion(formData);
        console.log(message);

        if (code === 200) {
          setIsLoading(false);
          toastr.success(message);
          setWillFilter("reload");
          handleModalClose();
        } else {
          console.log(code, "something");
        }
      } catch (error: any) {
        setIsLoading(false);
        console.log(error);
        if (error.message === "Request failed with status code 503") {
          toastr.error("Version already exist");
        }
      } finally {
        dispatch({ type: "action-loaded" });
      }
    }
  };

  useEffect(() => {
    if (item?.startDate) {
      const startDate = new Date(item.startDate);
      setStart_Date(startDate.toISOString().split("T")[0]);
    }
    if (item?.endDate) {
      const endDate = new Date(item.endDate);
      setEnd_Date(endDate.toISOString().split("T")[0]);
    }
    if (item?.version) {
      setAppVersion(item.version);
    }
    if (item?.releaseNote) {
      setReleaseNote(item?.releaseNote);
    }
    if (item?.downloadUrl) {
      setApkFile(item.downloadUrl);
    }
    // if (item?.line_discount_amount) {
    //   setDiscountAmount(item.line_discount_amount);
    // }
    // if (item?.itemid) {
    //   setSku(item.itemid);
    // }
  }, [item]);

  useEffect(() => {
    if (appVersion !== "" && start_date !== "" && releaseNote !== "") {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [appVersion, start_date, releaseNote]);

  return (
    <Form>
      <form className="form-container" onSubmit={handleSubmit}>
        <h2>{item ? "Update APK version" : "Upload New APK"}</h2>
        <div className="form-control">
          <div className="int-single">
            <label>App version</label>
            <input
              type="text"
              required
              placeholder="Enter version number"
              value={appVersion}
              name="version"
              onChange={(e) => setAppVersion(e.target.value)}
              onKeyPress={handleKeyPress(appVersion)}
            />
          </div>
          <div className="int-group">
            <div className="datee">
              <label>Start date</label>
              <input
                type="date"
                required
                name="startDate"
                value={start_date}
                onChange={(e) => setStart_Date(e.target.value)}
                min={new Date().toISOString().split("T")[0]}
              />
            </div>
            <div className="datee">
              <label>End date</label>
              <input
                type="date"
                name="endDate"
                value={end_date}
                onChange={(e) => setEnd_Date(e.target.value)}
                min={new Date().toISOString().split("T")[0]}
              />
            </div>
          </div>
          <RegionSelect
            selectedRegions={selectedRegions}
            setSelectedRegions={setSelectedRegions}
            regions={item?.regions ? item.regions : []}
          />

          <DepotSelect
            setSelectedDepots={setSelectedDepots}
            depots={item?.depots ? item.depots : []}
            selectedDepots={selectedDepots}
            selectedRegions={selectedRegions}
          />
          <div className="int-single">
            <label>Release note</label>
            <textarea
              required
              placeholder="Enter release note"
              value={releaseNote}
              name="releaseNote"
              onChange={(e) => setReleaseNote(e.target.value)}
            ></textarea>
          </div>
          <div className="int-single">
            <label>Upload APK</label>

            <label className="_label lrg">
              <img src={UploadImg} alt="" />
              <input
                type="file"
                accept=".apk"
                name="file"
                required
                disabled={item ? true : false}
                onChange={handleFileUpload}
              />
              {apkFile && <div className="img__container">{apkFile}</div>}
              <p>Upload file APK</p>
            </label>
          </div>
          <div className="btn-container">
            <button disabled={isDisabled || isLoading ? true : false}>
              Finish Upload
            </button>
          </div>
        </div>
      </form>
    </Form>
  );
};

const Form = styled.div`
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-container h2 {
    color: #262262;
    font-weight: 500;
    margin-bottom: 30px;
  }
  .form-control {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 400px;
    overflow-y: auto;
    padding: 0 15px;
  }
  .int-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .int-single {
    position: relative;
  }
  .int-group input {
    width: 48%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  .int-group select {
    width: 48%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  .int-single select,
  .int-single input {
    width: 100%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    outline: none;
  }
  .int-single input {
    cursor: text;
  }
  .int-single textarea {
    width: 100%;
    background: #fff;
    height: 120px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    outline: none;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .btn-container button {
    background: #1e1b4e;
    width: 200px;
    color: #fff;
    padding: 10px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }
  .btn-container button:disabled {
    background: #d4d3e0;
    cursor: auto;
  }
  .int-single input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100px;
    // z-index: 102;
    opacity: 0;
    cursor: pointer;
  }
  .int-single label {
    display: block;
    color: #888;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .int-single p {
    font-size: 0.8rem;
    color: #999;
  }
  .int-single ._label {
    background: #ececec;
    width: 100%;
    height: 50px;
    border-top: 2px dashed #d4d4d4;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .lrg {
    height: 100px !important;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .int-single ._label p {
    font-size: 0.9rem;
    color: #333;
    cursor: pointer;
    font-weight: normal;
  }
  .img__container {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;
    background: #fff;
    height: 100%;
    border-bottom: 2px dashed #d4d4d4;
  }
  .error_message {
    width: 100%;
    display: flex;
    justify-content: center;
    color: #d22730 !important;
    font-size: 0.9rem;
  }
  .datee {
    width: 48%;
  }
  .datee label {
    display: block;
    color: #888;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .datee input {
    width: 100%;
    cursor: auto;
  }
  .datee select {
    width: 100%;
  }
`;

export default CreateAppVersion;
