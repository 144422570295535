import React, { useState, Dispatch, SetStateAction, useEffect } from "react";
import styled from "styled-components";
import { ItemType } from "..";
import AddSvg from "../../../assets/svg/add.svg";
import SubtractSvg from "../../../assets/svg/subtract.svg";
import { Checkbox } from "components/CheckBox";

interface SinglerewardProp {
  setSelectedRewardProduct: Dispatch<SetStateAction<any>>;
  selectedRewardProduct: [];
  productData: any;
  item?: ItemType;
  availableRewards: any;
}

export const SingleReward: React.FC<SinglerewardProp> = ({
  productData,
  setSelectedRewardProduct,
  selectedRewardProduct,
  item,
  availableRewards,
}) => {
  const { product_name, product_code, image } = productData;

  const [value, setValue] = useState(1);
  const [controller, setController] = useState(false);
  const [isRollSelected, setIsRollSelected] = useState(false);
  const [isError, setIsError] = useState(false);

  const rollCount = 10;
  const increment = () => {
    setValue((prev) => prev + 1);
    setSelectedRewardProduct((prev: any) =>
      prev.map((data: any) =>
        data.product_code === product_code
          ? {
              ...data,
              quantity: value + 1,
              type: isRollSelected ? "roll" : "pack",
            }
          : data
      )
    );
  };
  const decrement = () => {
    if (value === 1) {
      setValue(1);
      setController(false);
      const newObj = selectedRewardProduct.filter(
        (data: any) => data.product_code !== product_code
      );
      setSelectedRewardProduct(newObj);
      return;
    }
    setValue((prev) => prev - 1);
    setSelectedRewardProduct((prev: any) =>
      prev.map((data: any) =>
        data.product_code === product_code
          ? {
              ...data,
              quantity: value - 1,
              type: isRollSelected ? "roll" : "pack",
            }
          : data
      )
    );
  };

  const handleSelectProduct = () => {
    setController(true);
    const newItem = {
      product_code,
      quantity: value,
      type: isRollSelected ? "roll" : "pack",
    };
    setSelectedRewardProduct((prev: any) => [...prev, newItem]);
  };

  const handleCheckBox = (e: any) => {
    setIsRollSelected(e.target.checked);
  };
  useEffect(() => {
    if (isRollSelected) {
      setSelectedRewardProduct((prev: any) =>
        prev.map((data: any) =>
          data.product_code === product_code ? { ...data, type: "roll" } : data
        )
      );
    } else {
      setSelectedRewardProduct((prev: any) =>
        prev.map((data: any) =>
          data.product_code === product_code ? { ...data, type: "pack" } : data
        )
      );
    }
  }, [isRollSelected]);

  useEffect(() => {
    if (!controller && isRollSelected) {
      setIsError(true);
    } else {
      setIsError(false);
      setSelectedRewardProduct((prev: any) =>
        prev.map((data: any) =>
          data.product_code === product_code ? { ...data } : data
        )
      );
    }
  }, [isRollSelected, controller]);

  useEffect(() => {
    if (item?.rewards) {
      const filterCriteria = item?.rewards.map((data: any) => {
        return {
          product_code: data.product_code,
        };
      });
      const filterObjects = (arrayToFilter: any, filterCriteria: any) => {
        return arrayToFilter.filter((obj: any) =>
          filterCriteria.some(
            (filterObj: any) => filterObj.product_code === obj.product_code
          )
        );
      };
      const filt = filterObjects(availableRewards, filterCriteria);

      item?.rewards.forEach((data: any) => {
        if (data.product_code === product_code) {
          let quantity = data.quantity;
          setController(true);
          setIsRollSelected(data.type === "roll" ? true : false);
          setValue(quantity);
          const newItem = {
            product_code,
            quantity: data.quantity,
            type: data.type,
          };
          setSelectedRewardProduct((prev: any) => [...prev, newItem]);
        }
      });
    }
  }, [item]);

  return (
    <Container>
      <div className="container_top">
        <div className="container_top_img">
          <img src={image} alt="img" />
        </div>
        <div className="container_top_det">
          <p>{product_name}</p>
        </div>
      </div>
      {controller && (
        <div className="container_select">
          <img src={SubtractSvg} alt="" onClick={decrement} />
          <h3>{value}</h3>
          <img src={AddSvg} alt="" onClick={increment} />
        </div>
      )}

      {!controller && (
        <div className="container_add" onClick={handleSelectProduct}>
          <p>Select product</p>
        </div>
      )}

      <div className="container_bottom">
        <Checkbox
          id={product_code}
          checked={isRollSelected}
          onChange={handleCheckBox}
        />
        <label htmlFor={product_code}>Add as Rolls</label>
      </div>
      {isError && (
        <div className="error">
          <p>Please select product first</p>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 180px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px
 }

 p{
  padding: 0 !important
 }

  .container_top {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  .container_top_img {
    width: 40px;
    height: 40px;
  }
  .container_top_img img {
    width: 100%;
    height: 100%;
  }
  .container_top_det {
    font-size: 0.8rem !important;
    text-align: center;
    text-transform: capitalize;
    width: 100%
  }
  .container_top_det p{
    width: auto;
    padding: 0
    text-align: center
  }

  .container_select {
    width: 100%;
    border: 1px solid #cfcfcf;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    margin: 15px 0
  }
  .container_select img {
    width: 15px;
    cursor: pointer;
  }
  .container_select h3 {
    font-size: 1rem
  }
  .container_add {
    width: 100%;
    border-radius: 4px;
    background: #262262;
    color: #fff;
    text-align: center
    font-size: .9rem;
    padding: 8px;
    cursor: pointer;
    margin: 10px 0;
  }
  .container_add p {
    text-align: center !important
  }
  .table-container {
    white-space: unset;
  }
  .container_bottom {
    display: flex;
    align-center: center;
    gap: 10px;
  }
  .container_bottom label {
    font-size: 0.9rem;
  }
  .error {
    font-size: 0.7rem;
    text-align: center;
    color: #FF0000
  }
`;
