import { useAppContext } from "context/AppContext";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  EmptyList,
  TableSkeleton,
  Pagination,
  Table,
  DynamicTab,
  TableTab,
} from "components";
import ContentLayout from "../../layouts/ContentLayout";
import BannerService from "services/BannerService";
import { DiscountActionModal } from "./DiscountActionModal";
import { useSearchParams } from "react-router-dom";
import { DiscountDetailsModal } from "./DiscountDetailsModal";
import { DiscountItemType } from ".";
import { formatArrayToString } from "utils/formatArrayToString";
import DiscountOfferForm from "./DiscountOffer/DiscountOfferForm";
import DiscountModal from "./DiscountOffer/DiscountModal";

const navItems = [
  { link: "Ads details", linkTo: "../ads-manager" },
  { link: "Discount SKU", linkTo: "../discount-offer" },
];

const discountHeaders = [
  { key: 1, item: "Item no" },
  { key: 2, item: "Product Name" },
  { key: 3, item: "Region" },
  { key: 4, item: "Amount" },
  { key: 5, item: "Start date" },
  { key: 6, item: "End date" },
  { key: 7, item: "Unit" },
  { key: 8, item: "Action" },
];

export const DiscountOffer = () => {
  const { state, dispatch } = useAppContext();
  const [tableData, setTableData] = useState<DiscountItemType[]>([]);
  const [isDiscountReset, setIsDiscountReset] = useState(false);
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
  const [skuProducts, setSkuProducts] = useState([]);
  const isLoading = state?.isLoading;
  const [willFilter, setWillFilter] = useState("");
  const [byRegion, setByRegion] = useState("");
  const [byStatus, setByStatus] = useState("");
  const [byDepots, setByDepots] = useState("");
  const [byAdsType, setByAdsType] = useState("");
  const [bySpecific, setBySpecific] = useState("");
  const [byCustomStart, setByCustomStart] = useState("");
  const [byCustomEnd, setByCustomEnd] = useState("");
  const [pageParams, setPageParams] = useSearchParams();
  const [page, setPage] = useState(() => {
    let p = Number(pageParams.get("page"));
    return p > 0 ? p : 1;
  });
  const [pages, setPages] = useState<number>(0);
  const [limit, setLimit] = useState(10);
  const [csvData, setCsvData] = useState();

  const convertToString = (itemKey: any) => {
    const convertedString = itemKey.join(" | ");

    return convertedString;
  };

  useEffect(() => {
    async function fetchData() {
      dispatch({ type: "data-loading" });
      try {
        const {
          data: {
            data: { products, meta },
          },
        } = await BannerService.getAllBundleProducts("active");

        setSkuProducts(products);
      } catch (error) {
      } finally {
        dispatch({ type: "data-loaded" });
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      dispatch({ type: "data-loading" });
      try {
        const {
          data: {
            data: { data, meta },
          },
        } = await BannerService.getAllDiscounts(
          // byRegion,
          // byStatus,
          // byAdsType,
          byCustomStart,
          byCustomEnd,
          limit,
          page
        );

        const csvData = data.map((item: DiscountItemType) => {
          return {
            id: item.id,
            product_id: item.itemid,
            product_name: item.product_name,
            discount_amount: item.line_discount_amount,
            quantity: item.quantity,
            region: convertToString(item.regions),
            start_date: item.start_date,
            end_date: item.end_date,
            // status: item.is_active ? "Active" : "Inactive",
            createdAt:
              new Date().toISOString().substr(0, 10) + "T00:00:00.000Z",
          };
        });
        setCsvData(csvData);
        setTableData(data);
        setPages(meta.pageCount);
        setPageParams({ page: page.toString() });
      } catch (error) {
      } finally {
        dispatch({ type: "data-loaded" });
      }
    }

    fetchData();
  }, [limit, page, willFilter, dispatch]);

  const handleDiscountModalOpen = () => {
    setIsDiscountModalOpen(true);
  };

  const handleDiscountModalClose = () => {
    setIsDiscountModalOpen(false);
    setIsDiscountReset(false);
  };

  const handleDiscountCloseButton = () => {
    setIsDiscountReset(true);
    setIsDiscountModalOpen(false);
  };

  return (
    <>
      <ContentLayout titleText="Promo Management" subText="Discount offers">
        <ContentWrapper>
          <ButtonContainer>
            <button className="button" onClick={handleDiscountModalOpen}>
              Create Discount
            </button>
          </ButtonContainer>
          <DiscountModal
            isDiscountOpen={isDiscountModalOpen}
            handleDiscountClose={handleDiscountModalClose}
            handleDiscountCloseButton={handleDiscountCloseButton}
          >
            <DiscountOfferForm
              skuProducts={skuProducts}
              handleDiscountModalClose={handleDiscountModalClose}
              setWillFilter={setWillFilter}
            />
          </DiscountModal>
          <DiscountTable>
            <DynamicTab
              canFilter={true}
              navItems={navItems}
              data={csvData}
              file={`discount-promo.csv`}
              setWillFilter={setWillFilter}
              setBySpecific={setBySpecific}
              setByCustomStart={setByCustomStart}
              setByCustomEnd={setByCustomEnd}
            />

            {isLoading ? (
              <TableSkeleton />
            ) : tableData.length === 0 ? (
              <EmptyList />
            ) : (
              <>
                <div className="table-container">
                  <Table
                    headers={discountHeaders}
                    items={tableData}
                    renderItem={Discount}
                  />
                </div>
                <Pagination
                  setLimit={setLimit}
                  limit={limit}
                  page={page}
                  pages={pages}
                  setPage={setPage}
                />
              </>
            )}
          </DiscountTable>
        </ContentWrapper>
      </ContentLayout>
    </>
  );
};

const Discount = (item: DiscountItemType) => {
  const [isViewDetails, setIsViewDetails] = useState(false);
  const [isMoreAction, setIsMoreAction] = useState(false);
  const [skuProducts, setSkuProducts] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const {
          data: {
            data: { products },
          },
        } = await BannerService.getAllBundleProducts("active");
        setSkuProducts(products);
      } catch (error) {}
    }
    fetchData();
  }, []);

  let startDate = new Date(item.start_date);
  let endDate = new Date(item.end_date);
  let discountType = item.type.toUpperCase();

  return (
    <tr key={item.id}>
      {/* <td onClick={() => setIsViewDetails(true)}>{item.itemid}</td> */}
      <td>{item.itemid}</td>
      <td>{item.product_name}</td>
      <td>
        <div className="depot">
          {item?.regions && formatArrayToString(item.regions, "region")}
          {item?.regions?.length > 2 && (
            <div className="depotDisplay">
              {item.regions.length > 1 &&
                item.regions.map((data, index) => {
                  return (
                    <span key={index}>
                      {data} {index !== item.regions.length - 1 && ", "}{" "}
                    </span>
                  );
                })}
            </div>
          )}
        </div>
      </td>
      <td>
        {discountType === "PRICE" && "₦"} {item.line_discount_amount}{" "}
        {discountType === "RATE" && "%"} OFF
      </td>
      <td>
        <p>{`${startDate.getDate()}/${
          startDate.getMonth() + 1
        }/${startDate.getFullYear()}`}</p>
      </td>
      <td>
        {" "}
        <p>{`${endDate.getDate()}/${
          endDate.getMonth() + 1
        }/${endDate.getFullYear()}`}</p>
      </td>
      <td>
        {item.quantity}{" "}
        {item.quantity ? ` ${item.uom.toUpperCase()} (S)` : "NIL"}
      </td>

      {/* <td>{item.is_active ? "Active" : "Not active"}</td> */}
      <td onClick={() => setIsMoreAction(true)} className="pointer three-dots">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="3"
          height="24"
          viewBox="0 0 3 24"
          fill="none"
        >
          <path
            d="M1.50016 15.9965L1.50016 16.0054"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.50016 11.9955L1.50016 12.0045"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.50016 7.99451L1.50016 8.00349"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </td>

      <DiscountActionModal
        item={item}
        display={isMoreAction}
        setIsModal={setIsMoreAction}
        skuProducts={skuProducts}
      />
      <DiscountDetailsModal
        item={item}
        display={isViewDetails}
        setIsModal={setIsViewDetails}
      />
    </tr>
  );
};

const ContentWrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  position: relative;
  padding-top: 3em;

  .chart-container {
    padding: 20px 0;
  }
`;

const ButtonContainer = styled.div`
  margin-bottom: 20px;
  padding-left: 30px;

  .button {
    background: #db8130;
    color: #160d05;
    padding: 8px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }
`;

const DiscountTable = styled.div`
  tbody tr {
    background-color: hsla(var(--clr-light));
    border-top: 8px solid #ececec;
    position: relative;
  }
  & :first-child {
    text-decoration: none !important;
    cursor: auto !important;
  }

  .prod {
    display: flex;
    align-items: center;

    span:last-child {
      margin-left: 6px;
    }
  }

  .product-img {
    width: 30px;
    height: 30px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .three-dots {
    display: inline-grid;
    place-items: center;
    width: 100%;
  }
  .depot {
    position: relative;
  }
  .depotDisplay {
    position: absolute;
    background: #f0f0f0;
    width: 250px;
    border-radius: 6px;
    padding: 5px 0;
    visibility: hidden;
    z-index: 3;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    word-wrap: break-word;
    justify-content: space-around;
    row-gap: 5px;
  }
  .depot:hover .depotDisplay {
    visibility: visible;
  }
`;
