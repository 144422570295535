import styled from "styled-components";
import { Link } from "react-router-dom";

const ForgotPasswordSuccess = () => {
  return (
    <Container>
      <div className="sign-in-wrap">
        <header className="logo">
          <span>
            <svg
              width="121"
              height="37"
              viewBox="0 0 121 37"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.0514 13.2496C15.0514 16.3184 13.6277 18.3155 10.4546 18.3155H5.69514V8.32987H10.4546C13.6277 8.32987 15.0514 10.2296 15.0514 13.2496ZM0 2.67944V36.6794H5.69514V23.4301H8.37999L14.6446 36.6794H21.2347L14.4412 22.8943C18.9974 21.433 20.9093 17.2439 20.9093 13.1035C20.9093 7.40437 17.4922 2.67944 10.658 2.67944H0Z"
                fill="white"
              />
              <path
                d="M31.9368 16.3062C34.7175 16.3062 36.9575 18.3569 37.0347 21.6113H26.8775C27.3023 18.2231 29.3492 16.3062 31.9368 16.3062ZM42.094 28.5212H36.2623C35.5671 30.1707 34.2926 31.5081 31.9754 31.5081C29.272 31.5081 27.1092 29.4574 26.8389 25.8018H42.4802C42.5961 24.9994 42.6347 24.1969 42.6347 23.3945C42.6347 16.0387 38.2706 11.1794 32.0913 11.1794C25.7575 11.1794 21.3547 16.1279 21.3547 23.9294C21.3547 31.6864 25.8733 36.6794 32.0913 36.6794C37.3823 36.6794 40.9354 33.0684 42.094 28.5212Z"
                fill="white"
              />
              <path
                d="M73.6346 21.6172C73.6346 32.1037 66.0276 36.6794 58.8593 36.6794C50.8229 36.6794 44.6346 31.0486 44.6346 22.0909C44.6346 12.5949 51.1309 7.02863 59.354 7.02863C67.8757 7.02863 73.6346 12.9394 73.6346 21.6172ZM50.0856 21.9079C50.0856 28.1201 53.8284 32.8035 59.102 32.8035C64.2542 32.8035 68.1184 28.1739 68.1184 21.7894C68.1184 16.9876 65.6076 10.8938 59.2233 10.8938C52.839 10.8938 50.0856 16.5139 50.0856 21.9079Z"
                fill="white"
              />
              <path
                d="M69.2165 24.9249C70.8728 18.4648 67.8117 11.7255 62.3794 9.87224C56.947 8.01897 51.2004 11.7535 49.544 18.2136C47.8877 24.6737 50.9487 31.413 56.3811 33.2663C61.8135 35.1196 67.5601 31.385 69.2165 24.9249Z"
                fill="#DB8130"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M62.8352 17.3536L55.8629 19.3993H55.8536L55.3029 18.2903C54.5562 18.5056 53.8188 18.721 53.0721 18.9471L53.3242 19.3562L55.0976 18.8394C55.8723 20.4113 56.619 21.8863 57.431 23.5228L57.8324 23.4044L58.3457 24.6102L57.851 24.7502L57.9537 25.2132L62.9566 23.7489L62.8539 23.286L58.7564 24.481L58.243 23.2752L62.4059 22.0586L62.8166 21.9401H62.8259V17.8489L62.8352 17.3536ZM61.0525 21.9617L60.2778 18.5918L62.4152 17.9673L62.4059 21.5633L61.0525 21.9617ZM60.1098 18.6456L60.8845 22.0155L59.1578 22.5215L58.383 19.1516L60.1098 18.6456ZM58.215 19.1947L58.9897 22.5646L57.6363 22.963L56.0776 19.8192L58.215 19.1947Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M59.055 25.1916C59.3164 25.1162 59.5871 25.2885 59.6524 25.59C59.7177 25.8806 59.5591 26.1929 59.2977 26.2682C59.0364 26.3436 58.7657 26.1713 58.7004 25.8699C58.635 25.5792 58.7937 25.2777 59.055 25.1916ZM59.1204 25.4823C58.999 25.5146 58.9244 25.6653 58.9524 25.8053C58.9804 25.9452 59.111 26.0314 59.2324 25.9991C59.3537 25.9668 59.4284 25.816 59.4004 25.6761C59.3724 25.5254 59.2417 25.4392 59.1204 25.4823Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M61.9951 24.3303C62.2565 24.255 62.5271 24.4272 62.5925 24.7287C62.6578 25.0301 62.4991 25.3316 62.2378 25.407C61.9764 25.4823 61.7058 25.3101 61.6404 25.0086C61.5751 24.7179 61.7338 24.4165 61.9951 24.3303ZM62.0604 24.621C61.9391 24.6533 61.8644 24.804 61.8924 24.944C61.9298 25.084 62.0511 25.1701 62.1818 25.1378C62.3031 25.1055 62.3778 24.9548 62.3498 24.8148C62.3125 24.6641 62.1818 24.578 62.0604 24.621Z"
                fill="white"
              />
              <path
                d="M99.7469 0V29.7587C99.7469 31.9452 99.7991 34.4427 99.9556 36.1177H95.7471L95.5384 31.8449H95.4341C93.9994 34.5931 90.8604 36.6794 86.6519 36.6794C80.4261 36.6794 75.635 31.6443 75.635 24.162C75.5829 15.9676 80.9043 10.9326 87.191 10.9326C91.1299 10.9326 93.7907 12.7079 94.9645 14.6938H95.0689V0H99.7469ZM95.0602 21.5141C95.0602 20.9525 95.008 20.1902 94.8515 19.6285C94.1559 16.78 91.6082 14.4431 88.0953 14.4431C83.2521 14.4431 80.3739 18.5152 80.3739 23.9514C80.3739 28.9363 82.9303 33.0586 87.9823 33.0586C91.1212 33.0586 93.9994 31.0726 94.8515 27.7126C95.008 27.1008 95.0602 26.489 95.0602 25.7769V21.5141Z"
                fill="white"
              />
              <path
                d="M116.459 36.1177L116.085 33.0184H115.929C114.494 34.9542 111.72 36.6794 108.051 36.6794C102.834 36.6794 100.173 33.1689 100.173 29.6083C100.173 23.6605 105.712 20.4008 115.659 20.451V19.9394C115.659 17.9034 115.076 14.2425 109.807 14.2425C107.416 14.2425 104.912 14.9546 103.103 16.0779L102.042 13.1291C104.173 11.8052 107.259 10.9426 110.503 10.9426C118.381 10.9426 120.294 16.0779 120.294 21.0126V30.2201C120.294 32.3565 120.398 34.4427 120.72 36.1177H116.459ZM115.772 23.5502C110.659 23.4499 104.86 24.3125 104.86 29.0968C104.86 31.9954 106.886 33.3695 109.277 33.3695C112.633 33.3695 114.763 31.3334 115.503 29.2472C115.659 28.7858 115.772 28.2843 115.772 27.823V23.5502Z"
                fill="white"
              />
            </svg>
          </span>
        </header>
        <div className="form">
          <svg
            width="180"
            height="180"
            viewBox="0 0 250 250"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_3695_9797)">
              <path
                d="M249.079 52.9533L240.519 44.3932L72.1177 187.382V212.795L80.6777 221.355C81.9048 222.582 83.8941 222.582 85.1216 221.355L249.08 57.3961C250.307 56.1696 250.307 54.1803 249.079 52.9533Z"
                fill="#8CA82F"
              />
              <path
                d="M220.328 28.6451L82.8995 166.074L29.6713 112.846C28.4443 111.619 26.455 111.619 25.2275 112.846L0.92029 137.154C-0.306763 138.381 -0.306763 140.37 0.92029 141.597L72.1177 212.795L240.519 44.3932L224.772 28.6456C223.545 27.418 221.555 27.418 220.328 28.6451Z"
                fill="#A5C244"
              />
            </g>
            <defs>
              <clipPath id="clip0_3695_9797">
                <rect width="250" height="250" fill="white" />
              </clipPath>
            </defs>
          </svg>

          <div className="form-header">
            <h3>Password Reset Successful</h3>
            <p>You can sign in with your new password</p>
          </div>
          <div className="submit">
            <Link to="/">Sign In</Link>
          </div>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
    height: 100vh;
    
    .sign-in-wrap {
      width: 100%;
      height: 100%;
      background-color: hsla(var(--clr-blue-shade-700));
      overflow: scroll;
    
      .logo {
        display: flex;
        justify-content: center;
        margin-top: 40px;
      }
    }
    .form {
        width: 600px;
        height: 400px;
      background-color: #fff;
      margin: 50px auto;
      padding: 40px 100px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
    
      .form-header {
        display: flex;
        flex-direction: column;
        gap: 15px;
        h3 {
          color: #262262;
          text-align: center;
          font-size: 1.3rem
        }
        p {
          color: #333;
          font-size: 12px;
          text-align: center;
        }
        p span {
          color: #060607;
          font-weight: bold;
          font-size: 12px;
          text-align: center;
        }
      }
      .submit {
        margin-top: 40px;
        display: flex;
        justify-content: center;
        text-align: center;
  
        a {
          padding: 12px 0;
          width: 360px;
          border: none;
          background-color: #db8130;
          border-radius: 5px;
          cursor: pointer;
          color: #060607;
          font-weight: 600;
          position: relative;
  
          :hover {
            opacity: 0.9;
            text-decoration: underline;
          }
    }
    `;

export default ForgotPasswordSuccess;
