import { useEffect, useState, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { ItemType } from "../CustomerSupportManagement";

interface FeatureTitleProps {
  setSurveyForm: Dispatch<SetStateAction<{}>>;
  setFormView: Dispatch<SetStateAction<string>>;
  item?: ItemType;
}

const FeatureTitleForm: React.FC<FeatureTitleProps> = ({
  setSurveyForm,
  setFormView,
  item,
}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleTypeForm = (e: any) => {
    e.preventDefault();
    setSurveyForm((prev: any) => {
      return {
        ...prev,
        feature: {
          salutation: title,
          information: description,
        },
      };
    });
    setFormView("featureCta");
  };

  useEffect(() => {
    if (item?.feature?.salutation) {
      setTitle(item?.feature?.salutation);
    }
    if (item?.feature?.information) {
      setDescription(item?.feature?.information);
    }
  }, [item]);

  return (
    <Form>
      <form className="form-container" onSubmit={handleTypeForm}>
        <h2>Feature Flag</h2>
        <div className="form-control">
          <div className="int-single">
            <input
              type="text"
              placeholder="Header/Title"
              required
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="int-single">
            <textarea
              placeholder="Type information here"
              required
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="btn-container">
            <button>Next</button>
          </div>
        </div>
      </form>
    </Form>
  );
};

const Form = styled.div`
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-container h2 {
    color: #262262;
    font-weight: 500;
    margin-bottom: 30px;
  }
  .form-control {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .int-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .int-group select {
    width: 48%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  .int-single select,
  .int-single input {
    width: 100%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  .int-single input {
    cursor: text;
  }
  .int-single textarea {
    width: 100%;
    background: #fff;
    height: 120px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .btn-container button {
    background: #1e1b4e;
    width: 200px;
    color: #fff;
    padding: 10px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }
  .btn-container button:disabled {
    background: #d4d3e0;
    cursor: auto;
  }
  .int-single input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100px;
    z-index: 102;
    opacity: 0;
  }
  .int-single label {
    display: block;
    color: #888;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .int-single p {
    font-size: 0.8rem;
    color: #999;
  }
  .int-single ._label {
    background: #ececec;
    width: 100%;
    height: 50px;
    border-top: 2px dashed #d4d4d4;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lrg {
    height: 100px !important;
  }
  .int-single ._label p {
    font-size: 0.9rem;
    color: #262262;
    cursor: pointer;
  }
  .error_message {
    width: 100%;
    display: flex;
    justify-content: center;
    color: #d22730 !important;
    font-size: 0.9rem;
  }
`;

export default FeatureTitleForm;
