import { useState, Dispatch, SetStateAction, useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { ItemType } from "..";

interface BundleFormProps {
  setFormView: Dispatch<SetStateAction<String>>;
  setBundleFormData: Dispatch<SetStateAction<{}>>;
  bundleFormData: any;
  isReset: boolean;
  item?: ItemType;
}

type FormData = {
  title: string;
  description: string;
};
export const BundlePromoForm: React.FC<BundleFormProps> = ({
  setFormView,
  setBundleFormData,
  bundleFormData,
  isReset,
  item,
}) => {
  const { handleSubmit } = useForm<FormData>();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleBundleForm = (data: FormData) => {
    setBundleFormData({
      title,
      description,
    });
    setFormView("sku");
  };

  useEffect(() => {
    const { title, description } = bundleFormData;
    if (isReset) {
      setDescription("");
      setTitle("");
    } else {
      setDescription(description);
      setTitle(title);
    }
  }, []);

  useEffect(() => {
    if (item?.title) {
      setTitle(item.title);
    }
    if (item?.description) {
      setDescription(item.description);
    }
  }, [item]);

  return (
    <Form>
      <form
        className="form-container"
        onSubmit={handleSubmit(handleBundleForm)}
      >
        <h2>Bundle Ads</h2>
        <div className="form-control">
          <div className="int-single">
            <input
              type="text"
              placeholder="Title"
              required
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              // {...register("title", {
              //   required: "This field is required",
              // })}
            />
            {/* {errors.title && (
              <p className="error_message">Title field is required</p>
            )} */}
          </div>
          <div className="int-single">
            <textarea
              placeholder="Description"
              required
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              // {...register("description", {
              //   required: "This field is required",
              // })}
            />
            {/* {errors.description && (
              <p className="error_message">Description field is required</p>
            )} */}
          </div>
          <div className="btn-container">
            <button>Next</button>
          </div>
        </div>
      </form>
    </Form>
  );
};

const Form = styled.div`
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-container h2 {
    color: #262262;
    font-weight: 500;
    margin-bottom: 30px;
  }
  .form-control {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .int-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .int-group select {
    width: 48%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  .int-single select,
  .int-single input {
    width: 100%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  .int-single input {
    cursor: text;
  }
  .int-single textarea {
    width: 100%;
    background: #fff;
    height: 70px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .btn-container button {
    background: #1e1b4e;
    width: 200px;
    color: #fff;
    padding: 10px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }
  .btn-container button:disabled {
    background: #d4d3e0;
    cursor: auto;
  }
  .int-single input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100px;
    z-index: 102;
    opacity: 0;
  }
  .int-single label {
    display: block;
    color: #888;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .int-single p {
    font-size: 0.8rem;
    color: #999;
  }
  .int-single ._label {
    background: #ececec;
    width: 100%;
    height: 50px;
    border-top: 2px dashed #d4d4d4;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lrg {
    height: 100px !important;
  }
  .int-single ._label p {
    font-size: 0.9rem;
    color: #262262;
    cursor: pointer;
  }
  .error_message {
    width: 100%;
    display: flex;
    justify-content: center;
    color: #d22730 !important;
    font-size: 0.9rem;
  }
`;
