import Api from "./Api";

const Services = {
  getOrders(
    page: number,
    limit: number,
    status: string,
    specificDate: string,
    startDate: string,
    endDate: string,
    orderType: string
  ) {
    const specificDateObject = specificDate ? new Date(specificDate) : null;

    // Calculate endDate as one day after specificDate
    const tempEndDate = specificDateObject
      ? new Date(specificDateObject.getTime() + 24 * 60 * 60 * 1000)
          .toISOString()
          .split("T")[0]
      : null;
    var params = {
      ...(Boolean(specificDate || startDate || endDate) && { type: "filter" }),
      ...(Boolean(specificDate) && {
        startDate: specificDate,
        endDate: tempEndDate,
      }),
      ...(Boolean(startDate) && { startDate }),
      ...(Boolean(endDate) && { endDate }),
      ...(Boolean(orderType) && { orderType }),
      deliveryStatus: status,
      limit,
      page,
    };
    if (orderType === "ALL") {
      params.orderType = "";
    }
    return Api().get(`order/status`, { params });
  },
  collectionWalletBalance() {
    return Api().get("order/collectionWalletBalance");
  },
  getOrdersByOutlet(
    outletId: string,
    page: number,
    limit: number,
    status: string,
    startDate?: string,
    endDate?: string
  ) {
    var params = {
      ...(Boolean(startDate) && { startDate }),
      ...(Boolean(endDate) && { endDate }),
      status,
      limit,
      page,
    };
    return Api().get(`/order/orderByOutlet/${outletId}`, { params });
  },
};

export default Services;
