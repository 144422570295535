import React from 'react';
import styled from 'styled-components';

type PaginationPropsType = {
	page: number;
	pages: number;
	limit: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
	setLimit: React.Dispatch<React.SetStateAction<number>>;
};

const Pagination = ({ page, pages, limit, setPage, setLimit }: PaginationPropsType) => {
	let middlePagination;

	if (pages <= 3) {
		middlePagination = [...Array(pages)].map((_, i) => (
			<button
				key={i + 1}
				onClick={() => setPage(i + 1)}
				disabled={page === i + 1}
				className='paginate'>
				{i + 1}
			</button>
		));
	} else {
		const startValue = Math.floor((page - 1) / 3) * 3;

		if (pages - startValue <= 3) {
			middlePagination = (
				<>
					{[...Array(3)].map((_, i) => (
						<button
							key={startValue + i + 1}
							disabled={page === startValue + i + 1}
							onClick={() => setPage(startValue + i + 1)}
							className='paginate'
							style={startValue + i + 1 > pages ? { display: 'none' } : undefined}>
							{startValue + i + 1}
						</button>
					))}
				</>
			);
		} else {
			middlePagination = (
				<>
					{[...Array(3)].map((_, i) => (
						<button
							key={startValue + i + 1}
							disabled={page === startValue + i + 1}
							onClick={() => setPage(startValue + i + 1)}
							className='paginate'>
							{startValue + i + 1}
						</button>
					))}
					<button className='paginate'>...</button>
					<button className='paginate' onClick={() => setPage(pages)} disabled={page === pages}>
						{pages}
					</button>
				</>
			);
		}
	}

	const handleLimitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setLimit(Number(e.target.value));
	};

	return (
		<PaginationWrapper>
			<div className='item-per-page'>
				<span>Items per page:</span>
				<select defaultValue={limit} onChange={handleLimitChange}>
					<option value={5}>5</option>
					<option value={10}>10</option>
					<option value={20}>20</option>
					<option value={50}>50</option>
					<option value={100}>100</option>
					<option value={200}>200</option>
					<option value={1000}>1000</option>
					<option value={2000}>2000</option>
					<option value={5000}>5000</option>

				</select>
			</div>
			<div className='pagination-main'>
				<div className='pagination-innerwrap'>
					<button
						className='paginate'
						onClick={() => setPage((prev) => prev - 1)}
						disabled={page === 1}>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'>
							<polyline points='15 18 9 12 15 6'></polyline>
						</svg>
					</button>
					{middlePagination}
					<button
						className='paginate'
						onClick={() => setPage((prev) => prev + 1)}
						disabled={page === pages}>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'>
							<polyline points='9 18 15 12 9 6'></polyline>
						</svg>
					</button>
				</div>
			</div>
		</PaginationWrapper>
	);
};

const PaginationWrapper = styled.div`
	height: 70px;
	width: 100%;
	position: sticky;
	bottom: 0;
	background-color: hsla(var(--clr-light));
	display: flex;
	box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.2);

	.item-per-page {
		display: flex;
		align-items: center;
		padding-left: 16px;
		padding-right: 24px;
		border-right: 2px solid #d6d6d6;
		color: #666666;

		select {
			border: none;
			margin-left: 8px;

			:focus {
				outline: none;
			}
		}
	}

	.pagination-main {
		display: flex;
		justify-content: center;
		flex: 1;
	}

	.pagination-innerwrap {
		display: flex;
		align-items: center;
	}
	button.paginate {
		width: 32px;
		height: 32px;
		background: none;
		border: 1px solid #262262;
		color: #262262;
		border-radius: 4px;
		font-size: 14px;
		font-weight: 700;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		:not(:first-child) {
			margin-left: 4px;
		}

		:disabled {
			cursor: not-allowed;
			background-color: #3c3872;
			color: #fff;
		}
	}
`;

export default Pagination;
