import Api from "./Api";

const Services = {
  getAllDepots() {
    return Api().get("banner-ads/depots");
  },
  addBanner(formData: any) {
    return Api().post("banner-ads", formData);
  },
  getAllBanner(
    region: any,
    active: any,
    type: string,
    depot: any,
    fromDate: string,
    toDate: string,
    limit?: number,
    page?: number
  ) {
    var params = {
      ...(Boolean(region) && { region }),
      ...(Boolean(active) && { active }),
      ...(Boolean(type) && { type }),
      ...(Boolean(depot) && { depot }),
      ...(Boolean(region) && { region }),
      ...(Boolean(fromDate) && { fromDate }),
      ...(Boolean(toDate) && { toDate }),
      limit,
      page,
    };

    if (active === "active") {
      params.active = true;
    } else if (active === "inActive") {
      params.active = false;
    }
    if (depot.length >= 1) {
      params.depot = depot.join(",");
    }
    if (region.length >= 1) {
      params.region = region.join(",");
    }

    console.log("BANNERS??", params);

    return Api().get("banner-ads", { params });
  },
  editBanner(id: string, query: any) {
    const params = query;

    return Api().put(`banner-ads/${id}`, params);
  },
  uploadImage(formData: FormData) {
    return Api().post("banner-ads/upload-banner-image", formData);
  },
  getAllBundleProducts(filter: any) {
    return Api().get(`product/bundle?filter=${filter}`);
  },
  addDiscount(formData: any) {
    return Api().post("product/discount/create", formData);
  },
  getAllDiscounts(
    startDate: string,
    endDate: string,
    limit?: number,
    page?: number
  ) {
    var params = {
      // ...(Boolean(region) && { region }),
      // ...(Boolean(active) && { active }),
      // ...(Boolean(type) && { type }),
      // ...(Boolean(region) && { region }),
      ...(Boolean(startDate) && { startDate }),
      ...(Boolean(endDate) && { endDate }),
      type: "filter",
      limit,
      page: !startDate || !endDate ? page : 1,
    };

    return Api().get("product/discount/all", { params });
  },
  updateDiscount(id: string, formData: any) {
    return Api().put(`product/discount/${id}`, formData);
  },
};

export default Services;
