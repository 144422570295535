import React, { useState, useEffect } from "react";
import Pagination from "../../components/Pagination";
import OrderService from "../../services/OrderManagementService";
import {
  useNavigate,
  useSearchParams,
  useOutletContext,
} from "react-router-dom";
import { formatMoney } from "../../utils/formatMoney";
import { useAppContext } from "context/AppContext";

import styled from "styled-components";
import { EmptyList, TableSkeleton } from "components";

const orderHeaders = [
  { key: 1, item: "Token" },
  { key: 2, item: "Payment Status" },
  { key: 3, item: "Region" },
  { key: 4, item: "Depot" },
  { key: 5, item: "Channel" },
  { key: 6, item: "Order type" },
  { key: 7, item: "Outlet ID" },
  { key: 8, item: "Reps ID" },
  { key: 9, item: "Quantity" },
  { key: 10, item: "Method" },
  { key: 11, item: "Price" },
  { key: 12, item: "Delivery Status" },
  { key: 13, item: "Created Date" },
  { key: 14, item: "Delivery Date" },
];

export type ItemType = {
  token: number;
  paymentStatus: string;
  region: string;
  depot: string;
  channel: string;
  type: string;
  outletID: number;
  repsID: string;
  quantity: number;
  method: string;
  price: number;
  status: string;
  deliveryDate?: any;
  delivery?: any;
  date: string;
  customerInfo: { [k: string]: string | number };
  products: {
    amount: number;
    image: string;
    productCode: string;
    tag: string;
    productName: string;
    quantity: number;
    _id: string;
  }[];
};
export type csvType = {
  token: number;
  paymentStatus: string;
  region: string;
  depot: string;
  channel: string;
  type: string;
  outletID: number;
  repsID: string;
  quantity: number;
  price: number;
  status: string;
  method: string;
  createdAt: string;
};

type RouterOutletContextType = [
  toCsv: (table: {}[]) => string,
  setText: React.Dispatch<React.SetStateAction<string>>,
  setFileName: React.Dispatch<React.SetStateAction<string>>,
  value: string,
  startDate: string,
  endDate: string,
  orderType: string,
  filter: string
];
type OrdersProp = {
  status: "all" | "awaiting" | "delivered" | "failed" | "partial";
};
const Orders = ({ status }: OrdersProp) => {
  const { state, dispatch } = useAppContext();
  const [tableData, setTableData] = useState<ItemType[]>([]);
  const [pageParams, setPageParams] = useSearchParams();
  const [page, setPage] = useState(() => {
    let p = Number(pageParams.get("page"));
    return p > 0 ? p : 1;
  });
  const [pages, setPages] = useState<number>(0);
  const [limit, setLimit] = useState(10);
  const [
    toCsv,
    setText,
    setFileName,
    value,
    startDate,
    endDate,
    orderType,
    filter,
  ] = useOutletContext<RouterOutletContextType>();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "data-loading" });
        const { data: d } = await OrderService.getOrders(
          page,
          limit,
          status,
          value,
          startDate,
          endDate,
          orderType.toUpperCase()
        );
        const { data, meta } = d.data;
        if (!data) {
          return;
        }

        let formattedData: ItemType[] = data.map(
          (item: { [k: string]: any }): ItemType => {
            item.deliveryStatus = item.delivery
              ? item.delivery.status
                ? item.delivery.status
                : "Not Confirmed"
              : item.isDeliveryConfirmed
              ? "Confirmed"
              : "Not Confirmed";
            return {
              token: item.token,
              paymentStatus: item.paymentStatus,
              region: item.customerInfo.region,
              depot: item.customerInfo.depot,
              channel: item.deviceChannel,
              type: item.type,
              outletID: item.customerInfo.urno,
              repsID: item.customerInfo.customercode,
              quantity: item.totalQuantity,
              price: item.totalAmount,
              status: item.deliveryStatus,
              date: item.createdAt,
              customerInfo: item.customerInfo,
              products: item.products,
              deliveryDate: item.deliveryDate,
              delivery: item.delivery,
              method: item.IsMobileMoney ? "wallet" : "cash",
            };
          }
        );
        let csvData: csvType[] = data.map(
          (item: { [k: string]: any }): csvType => {
            item.deliveryStatus = item.delivery
              ? item.delivery.status
                ? item.delivery.status
                : "Not Confirmed"
              : item.isDeliveryConfirmed
              ? "Confirmed"
              : "Not Confirmed";
            // item.deliveryStatus =
            //   item.delivery.status == "PARTIAL" && "PARTIALLY";
            return {
              token: item.token,
              paymentStatus: item.paymentStatus,
              region: item.customerInfo.region,
              depot: item.customerInfo.depot,
              channel: item.deviceChannel,
              type: item.type,
              outletID: item.customerInfo.urno,
              repsID: item.customerInfo.customercode,
              quantity: item.totalQuantity,
              price: item.totalAmount,
              status: item.deliveryStatus,
              method: item.IsMobileMoney ? "Wallet" : "Cash",
              createdAt: item.createdAt,
            };
          }
        );

        setTableData(formattedData);
        setText(toCsv(csvData));
        setFileName("all-orders.csv");
        setPages(meta.pageCount);
        setPageParams({ page: page.toString() });
      } catch (error) {
      } finally {
        dispatch({ type: "data-loaded" });
      }
    };
    fetchData();
  }, [limit, page, filter]);
  const handleNavigate = (item: ItemType) => {
    navigate("/dashboard/orders-management/order-details", { state: { item } });
  };

  return (
    <OrderWrap className="grid">
      {state?.isLoading ? (
        <TableSkeleton />
      ) : tableData.length === 0 ? (
        <EmptyList />
      ) : (
        <>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  {orderHeaders.map((header) => (
                    <th key={header.key}>{header.item}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData.map((item, index) => {
                  let date = new Date(item.date);
                  let deliveryDate = new Date(item?.deliveryDate);

                  return (
                    <tr key={index}>
                      <td onClick={() => handleNavigate(item)}>{item.token}</td>
                      <td>{item.paymentStatus}</td>
                      <td>{item.region}</td>
                      <td>{item.depot}</td>
                      <td>{item.channel}</td>
                      <td>{item.type || "NIL"}</td>
                      <td>{item.outletID}</td>
                      <td>{item.repsID}</td>
                      <td className="quantity">{item.quantity}</td>
                      <td className={`method ${item.method}`}>{item.method}</td>
                      <td>{formatMoney(item.price)}</td>

                      <td
                        className={`status ${
                          item.status == "Not Confirmed"
                            ? "Not_Confirmed"
                            : item.status
                        }`}
                      >
                        {item.status}
                      </td>
                      <td className="table-date">
                        <p>{`${date.getDate()}/${
                          date.getMonth() + 1
                        }/${date.getFullYear()}`}</p>
                        <p>{`${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`}</p>
                      </td>
                      <td className="table-date">
                        {item?.deliveryDate ? (
                          <>
                            {" "}
                            <p>{`${deliveryDate.getDate()}/${
                              deliveryDate.getMonth() + 1
                            }/${deliveryDate.getFullYear()}`}</p>
                            <p>{`${deliveryDate.getHours()}:${deliveryDate.getMinutes()}:${deliveryDate.getSeconds()}`}</p>
                          </>
                        ) : (
                          <p> NIL</p>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Pagination
            limit={limit}
            setLimit={setLimit}
            page={page}
            pages={pages}
            setPage={setPage}
          />
        </>
      )}
    </OrderWrap>
  );
};

const OrderWrap = styled.div`
  .table-container {
    min-height: calc(100vh - 370px);
  }

  th,
  td {
    font-size: 13px;
  }

  .table-date p:nth-child(2) {
    font-size: 11px;
  }
  .quantity {
    text-align: center;
  }
  .status {
    font-weight: 600;
  }
  .status.CONFIRMED {
    color: #198155;
  }
  .status.pending {
    color: #834d1d;
  }
  .status.Not_Confirmed {
    color: #834d1d;
  }
  .status.PARTIAL {
    color: #221f58;
  }
  .method {
    font-weight: 600;
    text-transform: capitalize;
  }
  .method.cash {
    color: #060607;
  }
  .method.wallet {
    color: #995a22;
  }
`;

export default Orders;
