import React from 'react';
import styled from 'styled-components';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { formatMoney } from '../utils/formatMoney';

const data = [
    {
        name: 'Jan',
        uv: 4000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'Feb',
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'Mar',
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Apr',
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: 'May',
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'Jun',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Jul',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
];

type ChartPropsType = {
    amount?: number,
}

const Chart = ({amount}: ChartPropsType) => {
    return (
        <ChartWrapper>

            <div className='chart-inner-wrap'>

                <div className='chart-details-wrap'>

                    <h3>Amount in Account</h3>
                    <p className='amount-text'>Total amount in collection wallet</p>

                    <p className='amount'>{formatMoney(amount || 0)}</p>

                    <div className='percentage-details'>
                        <span className='arrow-up'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#198155"
                                strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <line x1="12" y1="19" x2="12" y2="5"></line>
                                <polyline points="5 12 12 5 19 12"></polyline>
                            </svg>
                        </span>
                        <span className='percentage-value'>10.56%</span>
                    </div>

                </div>

                <div className='area-chart-wrap'>
                    <div className='periods'>
                        <button>weeks</button>
                        <button className='active-btn'>month</button>
                        <button>year</button>
                    </div>
                    <div className='area-chart-inner-wrap'>
                        <ResponsiveContainer width="100%" height={200}>
                            <AreaChart
                                width={500}
                                height={200}
                                data={data}
                                syncId="anyId"
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Area type="monotone" dataKey="pv" stroke="#198155" fill="#82ca9d" />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>

                </div>
            </div>
        </ChartWrapper>
    );
};

const ChartWrapper = styled.div`

    width: 850px;
    height: 280px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    border-radius: 8px;
    padding: 20px 30px;

    

    .chart-inner-wrap{
        display: flex;
    }

    .chart-details-wrap{
        width: 40%;

        h3{
            margin-top: 40px;
        }
        .amount-text{
            margin-top: .5rem;
        }
        .amount{
            margin-top: 1.8rem;
            font-size: 18px;
            font-weight: 600;
            color: #3c3872;
        }

        .percentage-details{
            display: flex;
            align-items: center;
            margin-top: 1.8rem;

            .arrow-up{
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background-color: rgba(25, 129, 85, 0.4);
                display: grid;
                place-items: center;
            }
            .percentage-value{
                margin-left: 6px;
                color: #198155;
                font-size: 13px;
            }
        }

    }
    .area-chart-wrap{
        width: 60%;
        display: flex;
        flex-direction: column;

        .periods{
            width: 200px;
            background-color: #A09EA2;
            padding: 5px;
            display: flex;
            justify-content: space-around;
            align-self: flex-end;
            margin-right: 28px;
            border-radius: 5px;
            

            button{
                background: none;
                border: none;
                color: #fff;
                padding: 5px;
                cursor: pointer;
                font-size: 13px;
                line-height: 1.2;
            }

            button.active-btn{
                background-color: #fff;
                color: #000;
                border-radius: 2px;
            }
        }
    }

`

export default Chart;
