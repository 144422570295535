import React, { useState, useEffect } from "react";
import Dropdown from "react-dropdown-select";
import styled from "styled-components";
import { useAppContext } from "context/AppContext";
import ProductService from "services/ProductService";

interface SelectFormProps {
  setRegion: any;
  regions: [];
}
export const SelectRegion: React.FC<SelectFormProps> = ({
  setRegion,
  regions,
}) => {
  const { dispatch } = useAppContext();
  const [options, setOptions] = useState<string[]>([]);
  const [exisitngRegions, setExistingRegions] = useState<any>();
  const [checkBox, setCheckBox] = useState(false);
  const [isSelectAllSelected, setIsSelectAllSelected] = useState(false);
  const [dropdownValue, setDropDownValue] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const {
          data: {
            data: { regions },
          },
        } = await ProductService.getAllRegions();

        setOptions(regions);
      } catch (error) {
        // Handle error
        console.log(error);
      } finally {
        dispatch({ type: "data-loaded" });
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    let filteredData;
    if (regions) {
      filteredData = options?.filter((obj: any) =>
        regions.includes(obj.name as never)
      );
    }

    if (regions) setRegion(filteredData);
    setExistingRegions(filteredData);
  }, [options]);

  const handleDropdownChange = (values: any) => {
    if (values.length < 1) {
      setRegion(values);
    }
    setRegion(values);

    setDropDownValue(values);
  };

  useEffect(() => {
    if (checkBox) {
      setRegion(options);
      setIsSelectAllSelected(true);
    } else {
      setIsSelectAllSelected(false);
      setRegion(dropdownValue);
    }
  }, [checkBox]);

  return (
    <Dropdown
      options={options}
      labelField="name"
      valueField="id"
      // onChange={(values: any) => setSelectedDepots(values)}
      onChange={handleDropdownChange}
      placeholder="Choose Region"
      multi
      color="#8D8B8F"
      values={exisitngRegions}
      className="select_dropdown"
      searchable
      searchBy="name"
      required
      disabled={isSelectAllSelected}
    />
  );
};
