import { useEffect, useState, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import SurveyService from "../../../services/SurveryService";
import { useAppContext } from "context/AppContext";
import LargeSpinner from "components/Loaders/LargeSpinner";
import { useNavigate } from "react-router-dom";
import { ItemType } from "../CustomerSupportManagement";
import toastr from "toastr";

interface SurveyDurationProps {
  handleCloseButton: () => void;
  surveyForm: {};
  item?: ItemType;
}

const SurveyDurationForm: React.FC<SurveyDurationProps> = ({
  surveyForm,
  handleCloseButton,
  item,
}) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { state, dispatch } = useAppContext();
  const navigate = useNavigate();

  const handleDurationForm = async (e: any) => {
    e.preventDefault();

    const formData: any = { ...surveyForm };

    formData.duration = { startDate, endDate };

    if (item) {
      try {
        setIsLoading(true);
        dispatch({ type: "action-loading" });
        console.log("jdsjdjdj", formData);

        const {
          data: { statusCode, data, message },
        } = await SurveyService.updateSurveyStatus(item._id, formData);

        if (statusCode === 200 && data) {
          toastr.success("Update sucessful");
          setIsLoading(false);
          handleCloseButton();
          navigate(`survey-questions/${item._id}`, { state: { item } });
        }
      } catch (error) {
      } finally {
        dispatch({ type: "action-loaded" });
      }
    } else {
      try {
        setIsLoading(true);
        dispatch({ type: "action-loading" });

        const response: any = await SurveyService.addSurvey(formData);

        if (response.data.data.message) {
          toastr.error(response.data.data.message);
          return;
        }
        let { _id: surveyId, surveyType } = response.data.data;
        if (response.status === 200) {
          setIsLoading(false);
          handleCloseButton();
          navigate(`survey-questions/${surveyId}`, { state: { surveyType } });
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      } finally {
        dispatch({ type: "action-loaded" });
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (item?.duration.startDate) {
      const startDate = new Date(item?.duration.startDate);
      setStartDate(startDate.toISOString().split("T")[0]);
    }
    if (item?.duration.endDate) {
      const endDate = new Date(item?.duration.endDate);
      setEndDate(endDate.toISOString().split("T")[0]);
    }
  }, [item]);
  return (
    <Form>
      <form className="form-container" onSubmit={handleDurationForm}>
        <h2>Duration of Survey</h2>
        <div className="form-control">
          <div className="int-group">
            <div className="datee">
              <label>Start date</label>
              <input
                type="date"
                required
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                min={new Date().toISOString().split("T")[0]}
              />
            </div>
            <div className="datee">
              <label>End date</label>
              <input
                type="date"
                required
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                min={new Date().toISOString().split("T")[0]}
              />
            </div>
          </div>
          <div className="btn-container">
            <button
              disabled={
                startDate === "" || endDate === "" || isLoading ? true : false
              }
            >
              {item ? "Edit Survey" : "Select"}
            </button>
          </div>
        </div>
      </form>
    </Form>
  );
};

const Form = styled.div`
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-container h2 {
    color: #262262;
    font-weight: 500;
    margin-bottom: 30px;
  }
  .form-control {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .int-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .int-single {
    position: relative;
  }
  .int-group input {
    width: 48%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  .int-single select,
  .int-single input {
    width: 100%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    outline: none;
  }
  .int-single input {
    cursor: text;
  }
  .int-single textarea {
    width: 100%;
    background: #fff;
    height: 70px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    outline: none;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .btn-container button {
    background: #1e1b4e;
    width: 200px;
    color: #fff;
    padding: 10px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }
  .btn-container button:disabled {
    background: #d4d3e0;
    cursor: auto;
  }
  .int-single input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100px;
    z-index: 102;
    opacity: 0;
    cursor: pointer;
  }
  .int-single label {
    display: block;
    color: #888;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .int-single p {
    font-size: 0.8rem;
    color: #999;
  }
  .int-single ._label {
    background: #ececec;
    width: 100%;
    height: 50px;
    border-top: 2px dashed #d4d4d4;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .lrg {
    height: 100px !important;
  }
  .int-single ._label p {
    font-size: 0.9rem;
    color: #262262;
    cursor: pointer;
  }
  .img__container {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;
    background: #fff;
    height: 100%;
    border-bottom: 2px dashed #d4d4d4;
  }

  .datee {
    width: 48%;
  }
  .datee label {
    display: block;
    color: #888;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .datee input {
    width: 100%;
  }
`;

const Loader = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .loader-p {
    position: relative;
    top: 100px;
    color: #5f6aa0;
  }
`;

export default SurveyDurationForm;
