import { Dispatch, useEffect, useState } from "react";
import { useAppContext } from "context/AppContext";
import styled from "styled-components";
import {
  EmptyList,
  TableSkeleton,
  Pagination,
  Table,
  DynamicTab,
  TableTab,
} from "components";
import ContentLayout from "../../layouts/ContentLayout";
import { ItemType } from ".";
import { ActionModal } from "./ActionModal";
import { ViewDetailsModal } from "./ViewDetailsModal";
import { CreateBundleForm } from "./BundleOffer/CreateBundleForm";
import { NormalPromoForm } from "./BundleOffer/NormalPromoForm";
import { BackDrop } from "components/BackDrop";
import { BundlePromoForm } from "./BundleOffer/BundlePromoForm";
import { SkuSelect } from "./BundleOffer/SkuSelect";
import { RewardSelect } from "./BundleOffer/RewardSelect";
import { BundleFormFinal } from "./BundleOffer/BundleFormFinal";
import { useSearchParams, useMatch } from "react-router-dom";
import BannerService from "services/BannerService";
import { formatArrayToString } from "utils/formatArrayToString";

const adsHeaders = [
  { key: 1, item: "Title" },
  { key: 2, item: "Promotion type" },
  { key: 3, item: "Depot" },
  { key: 4, item: "Start date" },
  { key: 5, item: "End date" },
  { key: 6, item: "Status" },
  { key: 7, item: "Action" },
];

const AdsManagement = () => {
  const { state, dispatch } = useAppContext();
  const [tableData, setTableData] = useState<ItemType[]>([]);
  const isLoading = state?.isLoading;
  const [allDepots, setAllDepots] = useState();
  const [isReset, setIsReset] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formView, setFormView] = useState<String>("create");
  const [skuProducts, setSkuProducts] = useState([]);
  const [selectedSkuProduct, setSelectedSkuProduct] = useState<any>([]);
  const [selectedRewardProduct, setSelectedRewardProduct] = useState<any>([]);
  const [selectedDepots, setSelectedDepots] = useState<[]>([]);
  const [selectedRegions, setSelectedRegions] = useState<[]>([]);
  const [bundleFormData, setBundleFormData] = useState({});
  const [willFilter, setWillFilter] = useState("");
  const [byRegion, setByRegion] = useState("");
  const [byStatus, setByStatus] = useState("");
  const [byDepots, setByDepots] = useState("");
  const [byAdsType, setByAdsType] = useState("");
  const [bySpecific, setBySpecific] = useState("");
  const [byCustomStart, setByCustomStart] = useState("");
  const [byCustomEnd, setByCustomEnd] = useState("");
  const [pageParams, setPageParams] = useSearchParams();
  const [page, setPage] = useState(() => {
    let p = Number(pageParams.get("page"));
    return p > 0 ? p : 1;
  });
  const [pages, setPages] = useState<number>(0);
  const [limit, setLimit] = useState(10);
  const [csvData, setCsvData] = useState();

  const navItems = [
    { link: "Ads details", linkTo: "../ads-manager" },
    { link: "Discount SKU", linkTo: "../discount-offer" },
  ];

  const convertToString = (itemKey: any) => {
    const convertedString = itemKey.join(" | ");

    return convertedString;
  };

  useEffect(() => {
    async function fetchData() {
      dispatch({ type: "data-loading" });
      try {
        const {
          data: {
            data: { products, meta },
          },
        } = await BannerService.getAllBundleProducts("active");

        setSkuProducts(products);
      } catch (error) {
      } finally {
        dispatch({ type: "data-loaded" });
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      dispatch({ type: "data-loading" });
      try {
        const {
          data: {
            data: { banners, meta },
          },
        } = await BannerService.getAllBanner(
          byRegion,
          byStatus,
          byAdsType,
          byDepots,
          byCustomStart,
          byCustomEnd,
          limit,
          page
        );

        const csvData = banners.map((item: ItemType) => {
          return {
            id: item._id,
            title: item.title,
            description: item.description,
            promotion_type:
              item.banner_type === "buy_actions"
                ? "Bundle Offer Ads"
                : "Promotion Ads",
            depots: convertToString(item.depots),
            start_date: item.start_date,
            end_date: item.end_date,
            status: item.is_active ? "Active" : "Inactive",
            createdAt:
              new Date().toISOString().substr(0, 10) + "T00:00:00.000Z",
          };
        });
        setCsvData(csvData);
        setTableData(banners);
        setPages(meta.pageCount);
        setPageParams({ page: page.toString() });
      } catch (error) {
      } finally {
        dispatch({ type: "data-loaded" });
      }
    }

    fetchData();
  }, [limit, page, willFilter, dispatch]);

  useEffect(() => {
    async function fetchData() {
      try {
        const {
          data: {
            data: { depots },
          },
        } = await BannerService.getAllDepots();

        let temptData = depots.map((data: any) => data.name);

        setAllDepots(temptData);
      } catch (error) {
        // Handle error
      } finally {
        dispatch({ type: "data-loaded" });
      }
    }
    fetchData();
  }, []);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setIsReset(false);
  };

  const handleCloseButton = () => {
    setIsReset(true);
    setIsModalOpen(false);
    setFormView("create");
    setSelectedSkuProduct([]);
    setSelectedRewardProduct([]);
  };

  return (
    <>
      <ContentLayout
        titleText="Promo Management"
        subText="Manage Bundle offers"
      >
        <ContentWrapper>
          <ButtonContainer>
            <button className="button" onClick={handleModalOpen}>
              Create Ads
            </button>
          </ButtonContainer>
          <BackDrop
            isOpen={isModalOpen}
            handleClose={handleModalClose}
            handleCloseButton={handleCloseButton}
          >
            {formView === "create" && (
              <CreateBundleForm setFormView={setFormView} />
            )}
            {formView === "normal" && (
              <NormalPromoForm
                setSelectedDepots={setSelectedDepots}
                selectedDepots={selectedDepots}
                setSelectedRegions={setSelectedRegions}
                selectedRegions={selectedRegions}
                handleCloseButton={handleCloseButton}
                setWillFilter={setWillFilter}
              />
            )}
            {formView === "bundle" && (
              <BundlePromoForm
                setFormView={setFormView}
                setBundleFormData={setBundleFormData}
                bundleFormData={bundleFormData}
                isReset={isReset}
              />
            )}
            {formView === "sku" && (
              <SkuSelect
                setFormView={setFormView}
                skuProducts={skuProducts}
                setSelectedSkuProduct={setSelectedSkuProduct}
                selectedSkuProduct={selectedSkuProduct}
              />
            )}
            {formView === "reward" && (
              <RewardSelect
                setFormView={setFormView}
                skuProducts={skuProducts}
                setSelectedRewardProduct={setSelectedRewardProduct}
                selectedRewardProduct={selectedRewardProduct}
                selectedSkuProduct={selectedSkuProduct}
                setSelectedSkuProduct={setSelectedSkuProduct}
              />
            )}
            {formView === "final" && (
              <BundleFormFinal
                setSelectedDepots={setSelectedDepots}
                selectedDepots={selectedDepots}
                setSelectedRegions={setSelectedRegions}
                selectedRegions={selectedRegions}
                bundleFormData={bundleFormData}
                selectedSkuProduct={selectedSkuProduct}
                selectedRewardProduct={selectedRewardProduct}
                handleCloseButton={handleCloseButton}
                setBundleFormData={setBundleFormData}
                setWillFilter={setWillFilter}
              />
            )}
          </BackDrop>
          <ProductsTable>
            <DynamicTab
              canFilter={true}
              navItems={navItems}
              data={csvData}
              allDepots={allDepots}
              file={`promo-ads.csv`}
              setByRegion={setByRegion}
              setByStatus={setByStatus}
              setByAdsType={setByAdsType}
              setByDepots={setByDepots}
              setWillFilter={setWillFilter}
              setBySpecific={setBySpecific}
              setByCustomStart={setByCustomStart}
              setByCustomEnd={setByCustomEnd}
            />

            {isLoading ? (
              <TableSkeleton />
            ) : tableData.length === 0 ? (
              <EmptyList />
            ) : (
              <>
                <div className="table-container">
                  <Table
                    headers={adsHeaders}
                    items={tableData}
                    renderItem={Bundle}
                  />
                </div>
                <Pagination
                  setLimit={setLimit}
                  limit={limit}
                  page={page}
                  pages={pages}
                  setPage={setPage}
                />
              </>
            )}
          </ProductsTable>
        </ContentWrapper>
      </ContentLayout>
    </>
  );
};

const Bundle = (item: ItemType) => {
  const [isViewDetails, setIsViewDetails] = useState(false);
  const [isMoreAction, setIsMoreAction] = useState(false);
  const [skuProducts, setSkuProducts] = useState([]);
  let startDate = new Date(item.start_date);
  let endDate = new Date(item.end_date);

  useEffect(() => {
    async function fetchData() {
      try {
        const {
          data: {
            data: { products, meta },
          },
        } = await BannerService.getAllBundleProducts("active");
        setSkuProducts(products);
      } catch (error) {}
    }
    fetchData();
  }, []);

  return (
    <tr key={item._id}>
      <td onClick={() => setIsViewDetails(true)}>{item.title}</td>
      <td>
        {item.banner_type === "buy_actions"
          ? "Bundle Offer Ads"
          : "Promotion Ads"}
      </td>
      <td>
        <div className="depot">
          {formatArrayToString(item.depots, "depot")}
          {item.depots.length > 2 && (
            <div className="depotDisplay">
              {item.depots.length > 1 &&
                item.depots.map((data, index) => {
                  return (
                    <span key={index}>
                      {data} {index !== item.depots.length - 1 && ", "}{" "}
                    </span>
                  );
                })}
            </div>
          )}
        </div>
      </td>
      <td>
        <p>{`${startDate.getDate()}/${
          startDate.getMonth() + 1
        }/${startDate.getFullYear()}`}</p>
      </td>
      <td>
        {" "}
        <p>{`${endDate.getDate()}/${
          endDate.getMonth() + 1
        }/${endDate.getFullYear()}`}</p>
      </td>

      <td>{item.is_active ? "Active" : "Not active"}</td>
      <td onClick={() => setIsMoreAction(true)} className="pointer three-dots">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="3"
          height="24"
          viewBox="0 0 3 24"
          fill="none"
        >
          <path
            d="M1.50016 15.9965L1.50016 16.0054"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.50016 11.9955L1.50016 12.0045"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.50016 7.99451L1.50016 8.00349"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </td>

      <ActionModal
        item={item}
        display={isMoreAction}
        setIsModal={setIsMoreAction}
        skuProducts={skuProducts}
      />
      <ViewDetailsModal
        item={item}
        display={isViewDetails}
        setIsModal={setIsViewDetails}
      />
    </tr>
  );
};

const ContentWrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  position: relative;
  padding-top: 3em;

  .chart-container {
    padding: 20px 0;
  }
`;
const ButtonContainer = styled.div`
  margin-bottom: 20px;
  padding-left: 30px;

  .button {
    background: #db8130;
    color: #160d05;
    padding: 8px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }
`;

const ProductsTable = styled.div`
  tbody tr {
    background-color: hsla(var(--clr-light));
    border-top: 8px solid #ececec;
    position: relative;
  }

  .prod {
    display: flex;
    align-items: center;

    span:last-child {
      margin-left: 6px;
    }
  }

  .product-img {
    width: 30px;
    height: 30px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .three-dots {
    display: inline-grid;
    place-items: center;
    width: 100%;
  }
  .depot {
    position: relative;
  }
  .depotDisplay {
    position: absolute;
    background: #f0f0f0;
    width: 250px;
    border-radius: 6px;
    padding: 5px 0;
    visibility: hidden;
    z-index: 3;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    word-wrap: break-word;
    justify-content: space-around;
    row-gap: 5px;
  }
  .depot:hover .depotDisplay {
    visibility: visible;
  }
`;

export default AdsManagement;
