import { TableTab } from "components";
import ContentLayout from "layouts/ContentLayout";
import AllOutlets from "./AllOutlets";
import users from "assets/svg/outlet-users.svg";
import styled from "styled-components";
import { useEffect, useState } from "react";
import OutletHistory from "./OutletHistory";
import { Routes, Route, Navigate, useParams } from "react-router-dom";
import Orders from "pages/OrderManagement/Orders";
import { Reps } from "pages/FinanceManagement/Reps/Reps";
import Services from "services/FinanceService";

const navItems = [
  { link: "All Orders", linkTo: "all-orders" },
  { link: "Pending", linkTo: "pending-orders" },
  { link: "Awaiting", linkTo: "awaiting-orders" },
  { link: "Delivered", linkTo: "delivered-orders" },
  { link: "Failed", linkTo: "failed-orders" },
  { link: "Canceled", linkTo: "cancelled-orders" },
];

type OutletDataType = {
  depot: string;
  name: string;
  outletname: string;
  region: string;
  repsId: string;
};

export const GrandOutlet = () => {
  const [data, setData] = useState<OutletDataType>();
  const { outletId } = useParams();

  useEffect(() => {
    // console.log("$$$L ", params);
    const fetchData = async () => {
      try {
        const {
          data: {
            data: { outletData },
          },
        } = await Services.getIndividualOutletWallet(outletId!);
        setData(outletData);
      } catch (error) {
        console.error(error);
      } finally {
        console.log("done");
      }
    };
    fetchData();
    return () => {
      // TODO
    };
  }, []);
  return (
    <ContentLayout titleText="Outlet Mangement" subText="Order history">
      <ContentWrapper>
        <div className="chart-container">
          <div className="flex flex-col outlets">
            <section className="flex top">
              <div className="section-icon">
                <img src={users} alt="icon" />
              </div>
              <h3 className="fs-600 fw-600">{data?.outletname}</h3>
            </section>
            <div className="flex flex-col">
              <p className="fs-600 fw-500 text-blue-dark-050">
                Urno:
                <span className="fs-400 fw-300 text-black-900">{outletId}</span>
              </p>
              <p className="fs-600 fw-500 text-blue-dark-050">
                Phone number:
                <span className="fs-400 fw-300 text-black-900">
                  0808 234 7854
                </span>
              </p>
              <p className="fs-600 fw-500 text-blue-dark-050">
                Depot:
                <span className="fs-400 fw-300 text-black-900">
                  {data?.region}
                </span>
              </p>
              <p className="fs-600 fw-500 text-blue-dark-050">
                Reps servicing {data?.outletname.split(" ")[1].toLowerCase()}
                (ID):
                <span className="fs-400 fw-300 text-black-900">
                  {data?.repsId}
                </span>
              </p>
            </div>
          </div>
        </div>
        <TableTab navItems={navItems} />
        <Routes>
          <Route index element={<Navigate to="all-orders" />} />
          <Route path="all-orders" element={<OutletHistory key="all" />} />
          <Route
            path="pending-orders"
            element={<OutletHistory key="pending" status="pending" />}
          />
          <Route
            path="awaiting-orders"
            element={<OutletHistory key="awaiting" status="awaiting" />}
          />
          <Route
            path="delivered-orders"
            element={<OutletHistory key="delivered" status="delivered" />}
          />
          <Route
            path="failed-orders"
            element={<OutletHistory key="failed" status="failed" />}
          />
          <Route
            path="cancelled-orders"
            element={<OutletHistory key="cancelled" status="canceled" />}
          />
        </Routes>
      </ContentWrapper>
    </ContentLayout>
  );
};

const ContentWrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  position: relative;
  .flex-col {
    flex-direction: column;
  }
  .chart-container {
    padding: 20px 0;
  }

  .top {
    align-items: center;
  }

  .outlets {
    width: 31.125em;
    background-color: #fff;
    margin: 0 auto;
    border-radius: 8px;
    padding: 16px 24px;
  }
  .section-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #262262;
    display: grid;
    place-items: center;
    margin-top: 5px;

    img {
      object-fit: contain;
    }
  }
  p > span {
    margin-left: 8px;
    color: #060607;
  }
  .summation {
    display: flex;
    flex-direction: column;

    .total-amount {
      margin-top: auto;
      color: #262262;
      font-size: 23px;
      font-weight: 600;
    }
  }
`;
