import React, { useState } from "react";
import styled from "styled-components";
import AuthenticationService from "services/AuthenticationService";
import ButtonSpinner from "components/Loaders/ButtonSpinner";

const regionsData = [
  "Lagos",
  "Middle Belt",
  "North",
  "South East",
  "South West",
];
const locationData = [
  "Lekki",
  "Marina",
  "Abuja",
  "Ilorin",
  "Festac",
  "Agbor",
  "Shagamu",
  "Ikorodu",
  "KAURA-1",
  "KADUNA SOUTH",
  "ENUGU",
  "AKURE",
  "ENUGU-2",
  "HADEJIA",
  "IWO",
  "KANO-2",
  "Aba",
  "Abeokuta",
  "Portharcourt",
  "Uyo",
  "Owerri",
  "Ibadan",
  "Benin",
  "Mushin",
  "Jos",
];

interface FormTypes {
  setIsInvite: React.Dispatch<React.SetStateAction<boolean>>;
}

type userType = {
  email: string;
  firstName: string;
  lastName: string;
  roles: string[];
  region: string;
  location: string;
};

const InviteUserForm = ({ setIsInvite }: FormTypes) => {
  const [user, setUser] = useState<userType>({} as userType);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setError("");
    setUser((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleRole = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setError("");
    let rolesArray: string[];
    let role = e.target.value;

    if (role === "ADMIN") {
      rolesArray = ["ADMIN", "STORE_KEEPER"];
    } else if (role === "SUPER_ADMIN") {
      rolesArray = ["ADMIN", "SUPER_ADMIN", "STORE_KEEPER"];
    } else if (role === "STORE_KEEPER") {
      rolesArray = ["STORE_KEEPER"];
    }
    console.log("ROLES", role);

    setUser((prev) => {
      return {
        ...prev,
        roles: rolesArray,
      };
    });
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setLoading(true);

      const { data } = await AuthenticationService.signUp(user);
      setLoading(false);
      setSuccess(true);
    } catch (error: any) {
      if (error.response) {
        const { data } = error.response;
        setError(data.message);
        setLoading(false);
      } else {
        setError("No response from server");
        setLoading(false);
      }
    }
  };
  const handleSendAnotherInvite = () => {
    setSuccess(false);
    setUser({} as userType);
  };

  return (
    <Wrap>
      {!success ? (
        <div className="dialog">
          <div className="flow">
            <h2 className="fs-500 fw-600 text-black-100">Invite User</h2>
            <p className="fs-300 fw-300 text-black-100">
              Fill in the necessary details to invite user
            </p>
          </div>
          <form onSubmit={handleSubmit} className="grid flow fs-200">
            <input
              name="email"
              type="text"
              placeholder="email"
              onChange={handleChange}
            />
            <input
              name="firstName"
              type="text"
              placeholder="firstname"
              onChange={handleChange}
            />
            <input
              name="lastName"
              type="text"
              placeholder="lastname"
              onChange={handleChange}
            />
            <select onChange={handleRole}>
              <option defaultValue="">role</option>
              <option value="ADMIN">Admin</option>
              <option value="SUPER_ADMIN">Super Admin</option>
              <option value="STORE_KEEPER">Store Keeper</option>
            </select>
            <select name="region" onChange={handleChange}>
              <option defaultValue="">region</option>
              {regionsData.map((region, idx) => (
                <option key={idx} value={region}>
                  {region}
                </option>
              ))}
            </select>
            <select name="location" onChange={handleChange}>
              <option defaultValue="">Location</option>
              {locationData.map((location, idx) => (
                <option key={idx} value={location}>
                  {location}
                </option>
              ))}
            </select>

            {!loading ? (
              <div className="flex btn-group">
                <button
                  className="btn btn-default-ghost pointer"
                  onClick={() => {
                    setIsInvite(false);
                  }}
                >
                  Cancel
                </button>
                <button className="btn btn-default pointer">Invite</button>
              </div>
            ) : (
              <div className="spinner-wrap">
                <ButtonSpinner />
              </div>
            )}
          </form>
          <span
            className="close-btn"
            onClick={() => setIsInvite(false)}
            style={{}}
          >
            <svg width="14" height="15" xmlns="http://www.w3.org/2000/svg">
              <path
                d="m11.596.782 2.122 2.122L9.12 7.499l4.597 4.597-2.122 2.122L7 9.62l-4.595 4.597-2.122-2.122L4.878 7.5.282 2.904 2.404.782l4.595 4.596L11.596.782Z"
                fill="#fff"
                fillRule="evenodd"
              />
            </svg>
          </span>
          {error !== "" && <div className="error-msg">{error}</div>}
        </div>
      ) : (
        <div className="success-dialog">
          <p>INVITATION HAS BEEN SENT TO USER'S EMAIL</p>
          <div className="action-btns">
            <button
              onClick={() => {
                setIsInvite(false);
              }}
            >
              Exit
            </button>
            <button onClick={handleSendAnotherInvite}>Send Another</button>
          </div>
        </div>
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  z-index: 9;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: grid;
  place-items: center;
  overflow: scroll;
  padding-top: 30px;
  padding-bottom: 30px;

  .dialog {
    width: 40%;
    border-radius: 5px;
    position: absolute;
    background-color: hsla(var(--clr-light));
    padding: 2em;

    & > *:first-child {
      --flow-space: 0.5rem;
    }
    form {
      --flow-space: 1.5rem;
      margin-top: 20px;
      input {
        outline: transparent solid 2px;
        width: 100%;
        height: 25px;
        padding-inline: 0.5em;
        &::placeholder {
          color: hsla(var(--clr-black-shade-100));
          opacity: 1;
        }
      }
      select {
        background: none;
        border: 1px solid hsla(var(--clr-black-tint-200));
        width: 100%;
        height: 25px;
        padding-inline: 0.5em;
        color: hsla(var(--clr-black-shade-100));
      }
      .btn-group {
        justify-content: center;
        button {
          width: unset;
        }
      }
    }

    .spinner-wrap {
      position: relative;
      padding: 10px;
      width: 80px;
      height: 40px;
      background-color: #262262;
      border-radius: 8px;
      margin-left: auto;
      margin-right: auto;
    }
    .error-msg {
      font-size: 13px;
      text-align: center;
      color: red;
      font-weight: 600;
      margin-top: 10px;
    }
    .close-btn {
      position: absolute;
      top: -15px;
      right: -10px;
      z-index: 9;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #262262;
      display: grid;
      place-items: center;
      cursor: pointer;
    }
  }

  .success-dialog {
    background-color: hsla(var(--clr-light));
    padding: 20px 30px;
    color: #28a667;
    font-weight: 600;
    border-radius: 8px;

    .action-btns {
      margin-top: 20px;

      button {
        padding: 10px 30px;
        background-color: #262262;
        color: #fff;
        font-weight: 600;
        border: none;
        border-radius: 4px;
        cursor: pointer;

        :last-child {
          margin-left: 10px;
        }
      }
    }
  }
`;

export default InviteUserForm;
