import { useEffect, useState, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import surveyCreate from "../../../assets/svg/survey-create.svg";
import { BackDrop } from "components/BackDrop";
import QuestionTypeForm from "./QuestionTypeForm";
import MultiChoiceForm from "./QuestionForm/MultiChoiceForm";
import MultiSelectForm from "./QuestionForm/MultiSelectForm";
import InlineForm from "./QuestionForm/InlineForm";
import MultiSelectLayout from "./QuestionsLayout/MultiSelectLayout";
import MultiChoiceLayout from "./QuestionsLayout/MultiChoiceLayout";
import InlineLayout from "./QuestionsLayout/InlineLayout";
import { ItemType } from "../CustomerSupportManagement";

interface QuestionTypeProps {
  setQuestionsForms: Dispatch<SetStateAction<any>>;
  questionsForms: any;
  item: ItemType;
  surveyType: string;
}

const CreateQuestion: React.FC<QuestionTypeProps> = ({
  setQuestionsForms,
  questionsForms,
  item,
  surveyType,
}) => {
  const [isModal, setIsModal] = useState(false);
  const [formView, setFormView] = useState("type");
  const [selectedId, setSelectedId] = useState("");

  const handleModalClose = () => {
    setIsModal(false);
  };

  const handleCloseButton = () => {
    setIsModal(false);
    setFormView("type");
  };

  useEffect(() => {
    if (item) {
      setQuestionsForms(item.question);
    }
  }, [item]);

  return (
    <ContentWrapper>
      {questionsForms.length == 0 &&
      (item?.question.length === 0 || item?.question.length == undefined) ? (
        <div className="question-forms">
          <img src={surveyCreate} alt="" width="400" />
          <div className="det-container">
            <p>To create a survey question click on “Create question”</p>
            <button
              className="btn btn-default pointer fs-110 fw-600 bt"
              onClick={() => setIsModal(true)}
            >
              Create Question
            </button>
          </div>
        </div>
      ) : null}

      {questionsForms.length >= 1 && (
        <Container>
          <div className="main-header">
            <h3>Customer Support Survey</h3>
          </div>
          <div className="question-lists">
            {questionsForms.map((data: any, index: any) => {
              if (data.optionType === "CHECK_BOX") {
                return (
                  <MultiSelectLayout
                    key={index}
                    {...data}
                    setQuestionsForms={setQuestionsForms}
                    questionsForms={questionsForms}
                    setSelectedId={setSelectedId}
                    setIsModal={setIsModal}
                    setFormView={setFormView}
                  />
                );
              }
              if (data.optionType === "SELECT") {
                return (
                  <MultiChoiceLayout
                    key={index}
                    {...data}
                    setQuestionsForms={setQuestionsForms}
                    questionsForms={questionsForms}
                    setSelectedId={setSelectedId}
                    setIsModal={setIsModal}
                    setFormView={setFormView}
                  />
                );
              }
              if (data.optionType === "INPUT") {
                return (
                  <InlineLayout
                    key={index}
                    {...data}
                    setQuestionsForms={setQuestionsForms}
                    questionsForms={questionsForms}
                    setSelectedId={setSelectedId}
                    setIsModal={setIsModal}
                    setFormView={setFormView}
                  />
                );
              }
            })}
          </div>
          <div className="bottom">
            {!(
              item?.surveyType === "feature flag" ||
              surveyType === "feature flag"
            ) && <p onClick={() => setIsModal(true)}>Add another question</p>}
          </div>
        </Container>
      )}

      <BackDrop
        isOpen={isModal}
        handleClose={handleModalClose}
        handleCloseButton={handleCloseButton}
      >
        {formView === "type" && (
          <QuestionTypeForm
            setQuestionsForms={setQuestionsForms}
            setFormView={setFormView}
          />
        )}
        {formView === "multipleChoice" && (
          <MultiChoiceForm
            setQuestionsForms={setQuestionsForms}
            setIsModal={setIsModal}
            handleCloseButton={handleCloseButton}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            questionsForms={questionsForms}
          />
        )}
        {formView === "mulitpleSelect" && (
          <MultiSelectForm
            setQuestionsForms={setQuestionsForms}
            setIsModal={setIsModal}
            handleCloseButton={handleCloseButton}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            questionsForms={questionsForms}
          />
        )}
        {formView === "inline" && (
          <InlineForm
            setQuestionsForms={setQuestionsForms}
            setIsModal={setIsModal}
            handleCloseButton={handleCloseButton}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            questionsForms={questionsForms}
          />
        )}
      </BackDrop>
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  height: 100%;
  .question-forms {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }

  .det-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
  .bt {
    padding: 0.5em 3em;
  }
`;

const Container = styled.div`
  height: 100%;
  overflow-y: scroll;
  position: relative;
  margin-top: 5rem;
  background: #fff;

  .main-header {
    text-align: center;
    margin: 20px 0;
    font-size: 1.2rem;
  }
  .bottom {
    padding: 50px 0;
    display: flex;
    justify-content: center;
  }
  .bottom p {
    text-align: center;
    text-decoration: underline;
    color: #1e1b4e;
    cursor: pointer;
  }
`;

export default CreateQuestion;
