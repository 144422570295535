import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import { useAppContext } from "context/AppContext";
import toastr from "toastr";
import styled from "styled-components";
import BannerService from "services/BannerService";
import { useForm, Controller } from "react-hook-form";
import ImageSvg from "../../../assets/svg/image.svg";
import { DepotSelect } from "components/DepotSelect";
import { RegionSelect } from "components/RegionSelect";
import { ItemType } from "..";

interface BundleFormProps {
  setSelectedDepots: Dispatch<SetStateAction<[]>>;
  setSelectedRegions: any;
  bundleFormData: {};
  selectedDepots: [];
  selectedRegions: [];
  selectedSkuProduct: any;
  selectedRewardProduct: any;
  handleCloseButton: () => void;
  item?: ItemType;
  setBundleFormData: Dispatch<SetStateAction<{}>>;
  setWillFilter?: any;
}

export const BundleFormFinal: React.FC<BundleFormProps> = ({
  setSelectedDepots,
  selectedDepots,
  selectedRegions,
  setSelectedRegions,
  bundleFormData,
  selectedSkuProduct,
  selectedRewardProduct,
  handleCloseButton,
  item,
  setBundleFormData,
  setWillFilter,
}) => {
  const [start_date, setStart_Date] = useState("");
  const [end_date, setEnd_Date] = useState("");
  const [itemProductImg, setItemProductImg] = useState<string | null>(null);
  const [itemProductImgUrl, setItemProductImgUrl] = useState<string | null>(
    null
  );
  const [smallImage, setSmallImage] = useState<string | null>(null);
  const [largeImage, setLargeImage] = useState<string | null>(null);
  const [smallImageUrl, setSmallImageUrl] = useState<string | null>(null);
  const [largeImageUrl, setLargeImageUrl] = useState<string | null>(null);
  const [created, setCreated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit } = useForm<FormData>();

  const { state, dispatch } = useAppContext();

  type FormData = {
    title: string;
    description: string;
    cta: String;
    depots: any;
    file: File;
    size: string;
    combinations: [];
    rewards: [];
    banner_type: string;
    start_date: string;
    end_date: string;
  };

  const handleSmallImage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileList = event.target.files;

    if (fileList && fileList.length > 0) {
      setSmallImage(URL.createObjectURL(fileList[0]));

      const formData = new FormData();
      formData.set(`file`, fileList[0]);
      try {
        const response = await BannerService.uploadImage(formData);

        setSmallImageUrl(response.data.data.imageUrl);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleLargeImage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileList = event.target.files;

    if (fileList && fileList.length > 0) {
      setLargeImage(URL.createObjectURL(fileList[0]));

      const formData = new FormData();
      formData.set(`file`, fileList[0]);
      try {
        const response = await BannerService.uploadImage(formData);
        setLargeImageUrl(response.data.data.imageUrl);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleItemProductImg = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileList = event.target.files;

    if (fileList && fileList.length > 0) {
      setItemProductImg(URL.createObjectURL(fileList[0]));

      const formData = new FormData();
      formData.set(`file`, fileList[0]);
      try {
        const response = await BannerService.uploadImage(formData);
        setItemProductImgUrl(response.data.data.imageUrl);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleBundleForm = async (data: any) => {
    // e.preventDefault();
    const { title, description }: any = bundleFormData;

    const modifiedData = selectedDepots.map((obj: any) => {
      if (obj.name && typeof obj.name === "string") {
        const match = obj.name.match(/^[^(]+/); // Match characters before the opening parenthesis
        obj.name = match ? match[0].trim() : ""; // Trim whitespace and update the depot property
      }
      return obj;
    });

    const depotNames = modifiedData.map((data: any) => data.name);
    const newDepot: any = depotNames.map(
      (word) => word.charAt(0) + word.slice(1).toLowerCase()
    );

    const regionNames = selectedRegions.map((data: any) => data.name);
    // const newRegion = regionNames.map(
    //   (word) => word.charAt(0) + word.slice(1).toLowerCase()
    // );
    let formData: any = {};

    formData = {
      title: title,
      description: description,
      depots: newDepot,
      regions: regionNames,
      start_date: start_date,
      end_date: end_date,
      combinations: selectedSkuProduct,
      rewards: selectedRewardProduct,

      banner_type: "buy_actions",
    };

    if (smallImage) {
      formData.small_image_url = smallImageUrl;
    }
    if (largeImage) {
      formData.large_image_url = largeImageUrl;
    }
    if (itemProductImg) {
      formData.product_image_url = itemProductImgUrl;
    }

    if (item) {
      try {
        dispatch({ type: "action-loading" });
        setIsLoading(true);
        const {
          data: {
            statusCode,
            data: { banner },
          },
        } = await BannerService.editBanner(item._id, formData);
        if (statusCode === 200) {
          setCreated(true);
          setIsLoading(false);
          toastr.success("Bundle Ads successfully updated");

          item.title = title;
          item.description = description;
          item.depots = newDepot;
          item.start_date = start_date;
          item.end_date = end_date;
          item.is_active = banner.is_active;
          item.combinations = banner.combinations;
          item.rewards = banner.rewards;
          handleCloseButton();
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch({ type: "action-loaded" });
        setIsLoading(false);
      }
    } else {
      try {
        dispatch({ type: "action-loading" });
        setIsLoading(true);

        const {
          data: { statusCode },
        } = await BannerService.addBanner(formData);
        if (statusCode === 200) {
          setCreated(true);
          setIsLoading(false);
          setWillFilter("normal");
          toastr.success("Bundle Ads successfully created");
          handleCloseButton();
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch({ type: "action-loaded" });
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (item?.start_date) {
      const startDate = new Date(item.start_date);
      setStart_Date(startDate.toISOString().split("T")[0]);
    }
    if (item?.end_date) {
      const endDate = new Date(item.end_date);
      setEnd_Date(endDate.toISOString().split("T")[0]);
    }
    if (item?.large_image_url) {
      setLargeImageUrl(item.large_image_url);
      setLargeImage(item.large_image_url);
    }
    if (item?.small_image_url) {
      setSmallImageUrl(item.small_image_url);
      setSmallImage(item.small_image_url);
    }
  }, [item]);

  return (
    <Form>
      <form
        className="form-container"
        onSubmit={handleSubmit(handleBundleForm)}
      >
        <h2>Select depot and banner</h2>
        <div className="form-control">
          <RegionSelect
            setSelectedRegions={setSelectedRegions}
            selectedRegions={selectedRegions}
            regions={item?.regions ? item.regions : []}
          />

          <DepotSelect
            setSelectedDepots={setSelectedDepots}
            depots={item?.depots ? item.depots : []}
            selectedDepots={selectedDepots}
            selectedRegions={selectedRegions}
          />
          <div className="int-group">
            <div className="datee">
              <label>Start date</label>
              <input
                type="date"
                required
                value={start_date}
                onChange={(e) => setStart_Date(e.target.value)}
                min={new Date().toISOString().split("T")[0]}
              />
            </div>
            <div className="datee">
              <label>End date</label>
              <input
                type="date"
                required
                value={end_date}
                onChange={(e) => setEnd_Date(e.target.value)}
                min={new Date().toISOString().split("T")[0]}
              />
            </div>
          </div>
          <div className="int-single">
            <label>Choose Product Image</label>

            <label className="_label">
              <img src={ImageSvg} alt="" />
              <input
                type="file"
                accept="image/*"
                onChange={handleItemProductImg}
              />
              {itemProductImg && (
                <div className="img__container">
                  <img src={itemProductImg} alt="" />
                </div>
              )}
            </label>
            <p>item product image will appear on Home Screen</p>
          </div>
          <div className="int-single">
            <label>Choose Image (Small)</label>

            <label className="_label">
              <img src={ImageSvg} alt="" />
              <input type="file" accept="image/*" onChange={handleSmallImage} />
              {smallImage && (
                <div className="img__container">
                  <img src={smallImage} alt="" />
                </div>
              )}
            </label>
            <p>Small image will appear on Home Screen</p>
          </div>
          <div className="int-single">
            <label>Choose Image (Large)</label>

            <label className="_label lrg">
              <img src={ImageSvg} alt="" />
              <input type="file" accept="image/*" onChange={handleLargeImage} />
              {largeImage && (
                <div className="img__container">
                  <img src={largeImage} alt="" />
                </div>
              )}
            </label>
            <p>Large image will appear on Cart Screen</p>
          </div>
          <div className="btn-container">
            <button
              disabled={selectedDepots.length < 1 || isLoading ? true : false}
            >
              {item ? "Update" : "Finish"}
            </button>
          </div>
        </div>
      </form>
    </Form>
  );
};

const Form = styled.div`
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-container h2 {
    color: #262262;
    font-weight: 500;
    margin-bottom: 30px;
  }
  .form-control {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 400px;
    overflow-y: auto;
    padding: 0 15px;
  }
  .int-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .int-group input {
    width: 48%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  .int-single {
    position: relative;
  }
  .int-single select,
  .int-single input {
    width: 100%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;

    outline: none;
  }
  .int-single input {
    cursor: text;
  }
  .int-single textarea {
    width: 100%;
    background: #fff;
    height: 70px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    outline: none;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .btn-container button {
    background: #1e1b4e;
    width: 200px;
    color: #fff;
    padding: 10px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }
  .btn-container button:disabled {
    background: #d4d3e0;
    cursor: auto;
  }
  .int-single input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100px;
    // z-index: 102;
    opacity: 0;
    cursor: pointer;
  }
  .int-single label {
    display: block;
    color: #888;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .int-single p {
    font-size: 0.8rem;
    color: #999;
  }
  .int-single ._label {
    background: #ececec;
    width: 100%;
    height: 50px;
    border-top: 2px dashed #d4d4d4;
    display: flex;
    justify-content: center;
    align-items: center;
    position relative
  }
  .lrg {
    height: 100px !important;
  }
  .int-single ._label p {
    font-size: 0.9rem;
    color: #262262;
    cursor: pointer;
  }
  .img__container {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;
    background: #fff;
    height: 100%;
    border-bottom: 2px dashed #d4d4d4;
  }
  .datee {
    width: 48%;
  }
  .datee label {
    display: block;
    color: #888;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .datee input {
    width: 100%;
  }
`;
