import React, { useState, useEffect } from "react";
import { useAppContext } from "context/AppContext";
import styled from "styled-components";
import ContentLayout from "../../../layouts/ContentLayout";
import backArrow from "../../../assets/svg/arrow-left.svg";
import { Link } from "react-router-dom";
import ConfigSkeleton from "./ConfigSkeleton";
import { BackDrop } from "components/BackDrop";
import EditOrderForm from "./EditOrderForm";
import EditWaitForm from "./EditWaitForm";
import ConfigService from "../../../services/ConfigService";

export interface ConfigInputs {
  product_limit_max: string;
  product_limit_min: string;
  order_duration: string;
}

const ConfigurationSettings = () => {
  const { state, dispatch } = useAppContext();
  const [formView, setFormView] = useState("order");
  const [isReset, setIsReset] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [willFilter, setWillFilter] = useState("");
  const [configInputs, setConfigInputs] = useState<ConfigInputs>({
    product_limit_max: "0",
    product_limit_min: "0",
    order_duration: "0",
  });
  const [configData, setConfigData] = useState<any>();

  const handleModalOpen = (view: string) => {
    setIsModalOpen(true);
    setFormView(view);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setIsReset(false);
  };

  const handleCloseButton = () => {
    setIsReset(true);
    setIsModalOpen(false);
  };

  useEffect(() => {
    setConfigData([
      {
        title: "Order Limit",
        content:
          "This is the minimum and maximum number of SKU a customer can order on Reoda",
        action: handleModalOpen,
        max: configInputs?.product_limit_max,
        min: configInputs?.product_limit_min,
      },
      {
        title: "Wait Time",
        content:
          "Amount of time to place another other and when rep will receive the order on their device",
        action: handleModalOpen,
        min: configInputs?.order_duration,
      },
    ]);
  }, [configInputs]);

  useEffect(() => {
    async function fetchData() {
      dispatch({ type: "data-loading" });
      try {
        const {
          data: {
            data: { config },
          },
        } = await ConfigService.getOrderConfig();
        setConfigInputs(config);
      } catch (error) {
      } finally {
        dispatch({ type: "data-loaded" });
      }
    }

    fetchData();
  }, [willFilter, dispatch]);

  return (
    <>
      <ContentLayout
        titleText="Configuration Settings"
        subText="Manage and configure Reoda app"
      >
        <Back className="back">
          <Link to="../dashboard/settings">
            <img src={backArrow} alt="back-arrow" />
            <p>Back</p>
          </Link>
        </Back>
        <Main className="grid container">
          <div className="head">
            <p>Configuration Setting</p>
          </div>
          <div className="contain">
            {configData?.map((item: any, index: string) => (
              <ConfigSkeleton
                key={index}
                title={item.title}
                content={item.content}
                action={item.action}
                max={item.max}
                min={item.min}
              />
            ))}
          </div>
        </Main>
        <BackDrop
          isOpen={isModalOpen}
          handleClose={handleModalClose}
          handleCloseButton={handleCloseButton}
        >
          {formView === "order" && (
            <EditOrderForm
              handleModalClose={handleModalClose}
              setWillFilter={setWillFilter}
              configInputs={configInputs}
              setConfigInputs={setConfigInputs}
            />
          )}

          {formView === "wait" && (
            <EditWaitForm
              handleModalClose={handleModalClose}
              setWillFilter={setWillFilter}
              configInputs={configInputs}
              setConfigInputs={setConfigInputs}
            />
          )}
        </BackDrop>
      </ContentLayout>
    </>
  );
};

const Main = styled.main.attrs(() => {})`
  margin: 0 auto;
  background: hsla(var(--clr-light));
  width: min(90%, 882px);
  padding-block: 2em 6em;
  margin-block: 2em;

  .head {
    p {
      font-size: 17px;
      font-weight: 600;
      color: #262262;
      margin-bottom: 20px;
    }
  }
  .contain {
    display: flex;
    flex-direction: column;
    gap: 60px;
  }
`;

const Back = styled.div`
  display: flex;
  a {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 1em 0 0 2em;
    color: #333;

    & p {
      font-weight: 600;
    }
  }
`;

export default ConfigurationSettings;
