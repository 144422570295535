import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAppContext } from "context/AppContext";
import styled from "styled-components";
import toastr from "toastr";
import BannerService from "services/BannerService";
import { useForm, Controller } from "react-hook-form";
import { SelectRegion } from "./SelectRegion";
import { DiscountItemType } from "..";

interface DiscountFormProps {
  skuProducts: any;
  handleDiscountModalClose: () => void;
  item?: DiscountItemType;
  setWillFilter?: any;
}

type FormData = {
  sku: string;
  region: any;
  discountAmount: string;
  amountType: string;
  quantity: string;
  item_type: string;
  start_date: string;
  end_date: string;
};

const DiscountOfferForm: React.FC<DiscountFormProps> = ({
  skuProducts,
  handleDiscountModalClose,
  item,
  setWillFilter,
}) => {
  const [sku, setSku] = useState("");
  const [product_name, setProductName] = useState("");
  const [region, setRegion] = useState<any>([]);
  const [discountAmount, setDiscountAmount] = useState("");
  const [amountType, setAmountType] = useState("");
  const [quantity, setQuantity] = useState("");
  const [item_type, setItem_Type] = useState("");
  const [start_date, setStart_Date] = useState("");
  const [end_date, setEnd_Date] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  let amountValidator;
  let itemValidator;
  let skuValidator;
  let editValidator;

  const { handleSubmit } = useForm<FormData>();

  const { dispatch } = useAppContext();

  const handleChange = (setter: any) => (e: any) => {
    const inputValue = e.target.value;
    if (inputValue === "" || /^[0-9]*\.?[0-9]*$/.test(inputValue)) {
      setter(inputValue);
    }
  };
  const handleKeyPress = (currentValue: any) => (e: any) => {
    const char = e.key;
    if (!/[0-9]/.test(char) && char !== "." && char !== "Backspace") {
      e.preventDefault();
    }
    if (char === "." && currentValue.includes(".")) {
      e.preventDefault();
    }
  };
  const handleProductChange = (e: any) => {
    const selectedSku = e.target.value;
    setSku(selectedSku);

    const selectedProduct = skuProducts.find((item: any) => {
      return item.product_code === selectedSku;
    });

    if (selectedProduct) {
      setProductName(selectedProduct.product_name);
    } else {
      setProductName("");
    }
  };

  const onSubmit = async () => {
    const regionNames = region.map((data: any) => data.name);

    let formData: any = {};
    formData = {
      itemId: sku,
      productName: product_name,
      discountPrice: Number(discountAmount),
      type: amountType === "amount" ? "PRICE" : "RATE",
      quantity: Number(quantity),
      uom: item_type,
      startDate: start_date,
      endDate: end_date,
      regions: regionNames,
    };

    if (item) {
      try {
        dispatch({ type: "action-loading" });

        const {
          data: { statusCode },
        } = await BannerService.updateDiscount(item.id, formData);
        if (statusCode === 200) {
          const updatedProduct = skuProducts.find((item: any) => {
            return item.product_code === sku;
          });

          toastr.success("Discount successfully updated");

          item.product_name = updatedProduct ? updatedProduct.product_name : "";
          item.quantity = quantity;
          item.line_discount_amount = discountAmount;
          item.uom = item_type;
          item.type = amountType === "percent" ? "RATE" : "PRICE";
          item.regions = regionNames;
          item.start_date = start_date;
          item.end_date = end_date;
          handleDiscountModalClose();
        }
      } catch (error: any) {
        console.log(error);
        toastr.error(error.message);
      } finally {
        dispatch({ type: "action-loaded" });
      }
    } else {
      try {
        dispatch({ type: "action-loading" });

        const {
          data: { statusCode, message },
        } = await BannerService.addDiscount(formData);

        if (statusCode === 200) {
          toastr.success(message);
          setWillFilter("reload");
          handleDiscountModalClose();
        }
      } catch (error: any) {
        console.log(error);
        toastr.error(error.message);
      } finally {
        dispatch({ type: "action-loaded" });
      }
    }
  };

  useEffect(() => {
    if (item?.start_date) {
      const startDate = new Date(item.start_date);
      setStart_Date(startDate.toISOString().split("T")[0]);
    }
    if (item?.end_date) {
      const endDate = new Date(item.end_date);
      setEnd_Date(endDate.toISOString().split("T")[0]);
    }
    if (item?.quantity) {
      setQuantity(item.quantity);
    }
    if (item?.type) {
      setAmountType(item?.type == "price" ? "amount" : "percent");
    }
    if (item?.uom) {
      setItem_Type(item.uom);
    }
    if (item?.line_discount_amount) {
      setDiscountAmount(item.line_discount_amount);
    }
    if (item?.itemid) {
      setSku(item.itemid);
    }
  }, [item]);

  useEffect(() => {
    amountValidator =
      amountType === "amount" || amountType === "percent" ? false : true;
    itemValidator = item_type === "roll" || item_type === "pack" ? false : true;
    skuValidator =
      sku == "disabled selected  hidden" || sku == "" ? true : false;
    editValidator = item ? false : true;

    if (item) {
      setIsDisabled(false);
      return;
    }

    setIsDisabled(
      amountValidator
        ? true
        : itemValidator
        ? true
        : skuValidator
        ? true
        : itemValidator
        ? true
        : false
    );
  }, [item_type, amountType, sku, item]);

  return (
    <Form>
      <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
        <h2>{item ? "Update Discount" : "Create Discount"}</h2>
        <div className="form-control">
          <div className="int-single">
            <label>Item SKU</label>
            <select required value={sku} onChange={handleProductChange}>
              <option value="disabled selected  hidden">Item SKU</option>
              {skuProducts &&
                skuProducts.map((product: any) => (
                  <option
                    key={product.product_code}
                    value={product.product_code}
                  >
                    {product.product_name}
                  </option>
                ))}
            </select>
          </div>

          <SelectRegion
            setRegion={setRegion}
            regions={item?.regions ? item.regions : []}
          />
          <div className="int-group">
            <div className="datee">
              <label>Discount amount</label>
              <input
                type="text"
                required
                // placeholder="Discount amount"
                value={discountAmount}
                onChange={handleChange(setDiscountAmount)}
                onKeyPress={handleKeyPress(discountAmount)}
              />
            </div>
            <div className="datee">
              <label>Amount or %</label>
              <select
                value={amountType}
                required
                onChange={(e) => setAmountType(e.target.value)}
              >
                <option value=" disabled selected  hidden">
                  Select amount type
                </option>
                <option value="amount">Naira</option>
                <option value="percent">Percentage (%)</option>
              </select>
            </div>
          </div>
          <div className="int-single">
            <label>Start date</label>
            <input
              type="date"
              required
              value={start_date}
              onChange={(e) => setStart_Date(e.target.value)}
              min={new Date().toISOString().split("T")[0]}
            />
          </div>
          <div className="int-single">
            <label>End date</label>
            <input
              type="date"
              required
              value={end_date}
              onChange={(e) => setEnd_Date(e.target.value)}
              min={new Date().toISOString().split("T")[0]}
            />
          </div>
          <div className="int-group">
            <div className="datee">
              <label>Quantity</label>
              <input
                type="text"
                required
                // placeholder="Quantity"
                value={quantity}
                onChange={handleChange(setQuantity)}
                onKeyPress={handleKeyPress(quantity)}
              />
            </div>
            <div className="datee">
              <label>Rolls or Pack</label>
              <select
                value={item_type}
                onChange={(e) => setItem_Type(e.target.value)}
              >
                <option value=" disabled selected  hidden">
                  Select item type
                </option>
                <option value="roll">Rolls</option>
                <option value="pack">Pack</option>
              </select>
            </div>
          </div>
          <div className="btn-container">
            <button disabled={isDisabled}>
              {item ? "Update Promo" : "Confirm Promo"}
            </button>
          </div>
        </div>
      </form>
    </Form>
  );
};

const Form = styled.div`
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-container h2 {
    color: #262262;
    font-weight: 500;
    margin-bottom: 30px;
  }
  .form-control {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 400px;
    overflow-y: auto;
    padding: 0 15px;
  }
  .int-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .int-single {
    position: relative;
  }
  .int-group input {
    width: 48%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  .int-group select {
    width: 48%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  .int-single select,
  .int-single input {
    width: 100%;
    background: #fff;
    height: 40px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    outline: none;
  }
  .int-single input {
    cursor: text;
  }
  .int-single textarea {
    width: 100%;
    background: #fff;
    height: 70px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    outline: none;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .btn-container button {
    background: #1e1b4e;
    width: 200px;
    color: #fff;
    padding: 10px 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }
  .btn-container button:disabled {
    background: #d4d3e0;
    cursor: auto;
  }
  .int-single input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100px;
    // z-index: 102;
    opacity: 0;
    cursor: pointer;
  }
  .int-single label {
    display: block;
    color: #888;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .int-single p {
    font-size: 0.8rem;
    color: #999;
  }
  .int-single ._label {
    background: #ececec;
    width: 100%;
    height: 50px;
    border-top: 2px dashed #d4d4d4;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .lrg {
    height: 100px !important;
  }
  .int-single ._label p {
    font-size: 0.9rem;
    color: #262262;
    cursor: pointer;
  }
  .img__container {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;
    background: #fff;
    height: 100%;
    border-bottom: 2px dashed #d4d4d4;
  }
  .error_message {
    width: 100%;
    display: flex;
    justify-content: center;
    color: #d22730 !important;
    font-size: 0.9rem;
  }
  .datee {
    width: 48%;
  }
  .datee label {
    display: block;
    color: #888;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .datee input {
    width: 100%;
    cursor: auto;
  }
  .datee select {
    width: 100%;
  }
`;

export default DiscountOfferForm;
