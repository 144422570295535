import Avatar from 'assets/images/placeholder.png';
import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import styled from 'styled-components';
import { AdminUserType } from './Admin';
import AuthService from 'services/AuthenticationService';
import ButtonSpinner from 'components/Loaders/ButtonSpinner';

interface AdminUserExt {
	item: AdminUserType;
	setTableData: Dispatch<React.SetStateAction<AdminUserType[]>>;
}

export const AdminUser: React.FC<AdminUserExt> = ({ item, setTableData }) => {
	const [isModal, setIsModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	async function onBlock(id: string, block: boolean) {
		try {
			setIsLoading(true);
			const response = await AuthService.blockUser(block, id);
			console.log('$$: ', response);
			setTableData((prev) => {
				return prev.map((user) => {
					var needsUpdate = { ...user };
					if (item._id === user._id) {
						needsUpdate.blocked = block;
					}
					return needsUpdate;
				});
			});
		} catch (error) {
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<>
			<Tr>
				<td className='flex-inline name'>
					<span className='admin-img'>
						<img src={Avatar} alt='small avatar' />
					</span>
					<span>
						{item.firstName} {item.lastName}
					</span>
				</td>
				<td>{item.email}</td>
				<td>{item._id}</td>
				<td>{item.location || 'N/A'}</td>
				<td>{item.isActive ? 'Active' : 'Inactive'}</td>
				<td className='roles'>{item.roles.join(', ')}</td>
				<td
					className='pointer three-dots'
					onClick={() => {
						setIsModal(true);
					}}>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='3'
						height='24'
						viewBox='0 0 3 24'
						fill='none'>
						<path
							d='M1.50016 15.9965L1.50016 16.0054'
							stroke='#333333'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M1.50016 11.9955L1.50016 12.0045'
							stroke='#333333'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M1.50016 7.99451L1.50016 8.00349'
							stroke='#333333'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
				</td>
				{isModal && (
					<>
						<td
							style={{
								backgroundColor: 'rgba(0, 0, 0, 0.6)',
								inset: 0,
								position: 'fixed',
								transition: 'transform 600ms ease-in-out',
								zIndex: 99,
							}}
						/>

						<td className='modal'>
							<span
								className='pointer'
								onClick={() => setIsModal(false)}
								style={{
									position: 'absolute',
									top: '-10px',
									right: '-5%',
									zIndex: 9,
									backgroundColor: 'hsla(var(--clr-blue-tint-050))',
									borderRadius: '50%',
									height: '24px',
									width: '24px',
									display: 'inline-grid',
									placeItems: 'center',
								}}>
								{isLoading ? (
									<ButtonSpinner />
								) : (
									<svg width='14' height='15' xmlns='http://www.w3.org/2000/svg'>
										<path
											d='m11.596.782 2.122 2.122L9.12 7.499l4.597 4.597-2.122 2.122L7 9.62l-4.595 4.597-2.122-2.122L4.878 7.5.282 2.904 2.404.782l4.595 4.596L11.596.782Z'
											fill='#FFF'
											fillRule='evenodd'
										/>
									</svg>
								)}
							</span>
							<p
								className='fs-100 fw-400 text-black-900 pointer'
								onClick={() => {
									onBlock(item._id, !item.blocked);
								}}>
								{item.blocked ? 'Unblock' : 'Block'}
							</p>
						</td>
					</>
				)}
			</Tr>
		</>
	);
};

const Tr = styled.tr.attrs(() => {})`
	position: relative;
	
	.admin-img {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		overflow: hidden;
		img {
			height: 100%;
			width: 100%;
			object-fit: contain;
		}
	}
	.roles {
		font-size: 12px;
	}

	.modal {
		background-color: hsla(var(--clr-light));
		position: absolute;
		z-index: 999;
		right: 5%;
		top: 0;
		padding: 0.7em 0;
		width: 9.375em;
		p {
			padding: 0.5em 1em;
			width: 100%;
			text-align: left;
			&:hover {
				background: hsla(var(--clr-yellow-tint-900));
			}
		}
	}
	.three-dots {
		display: inline-grid;
		place-items: center;
		width: 100%;
	}
`;
